import gql from 'graphql-tag';
import { travelEventFragment } from '../fragments/TravelEvent';
export const GQL_TRAVEL_EVENTS_SEARCH = gql`
  {
    travelEvents {
      ...TravelEvent
    }
  }
  ${travelEventFragment}
`;
export const GQL_TRAVEL_EVENTS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $travelEventFilter: TravelEventFilter) {    
    travelEventsPaginated(first: $pageSize, after: $cursor, travelEventFilter: $travelEventFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...TravelEvent
        }
      }
    }    
  }
  ${travelEventFragment}
`;
export const GQL_TRAVEL_EVENT_STORE = gql`
  mutation StoreTravelEvent($attributes: TravelEventAttributes!) {
    storeTravelEvent(input: { attributes: $attributes }) {
      travelEvent {
        ...TravelEvent
      }
      errors
    }
  }
  ${travelEventFragment}
`;