import gql from "graphql-tag";
import { freighterOperationSimpleFragment } from "./FreighterOperationSimple";
export const freighterOperationFragment = gql`
  fragment FreighterOperation on FreighterOperation { 
    ...FreighterOperationSimple
    parent {
      ...FreighterOperationSimple
    },
  }
  ${freighterOperationSimpleFragment}  
`;