import { dateOrToday, sanitizeBackend } from 'grifo-components';
import { BY_TRAVEL, COMPLETE, DRIVER, LOADING_FREE, NORMAL, QUOTING, SCHEDULING, UNLOADING_FREE, WITHOUT_CANVAS, WITHOUT_INSURANCE, WITHOUT_TRACKER } from "../constants/freights/FreightFieldsOptions";
export const availableFreightDefaultBuilder = (record, props = {}) => {
  const object = record || {};
  return {
    action: props.action,
    transactionId: object.transactionId,
    situation: object.situation || SCHEDULING,
    tracker: object.tracker || WITHOUT_TRACKER,
    insurance: object.insurance || WITHOUT_INSURANCE,
    priority: object.priority || NORMAL,
    loadingTax: object.loadingTax || LOADING_FREE,
    unloadingTax: object.unloadingTax || UNLOADING_FREE,
    toll: object.toll || DRIVER,
    kind: object.kind || COMPLETE,
    canvas: object.canvas || WITHOUT_CANVAS,
    description: object.description,
    collectionDateStart: dateOrToday(object.collectionDateStart),
    collectionDateEnd: sanitizeBackend(object.collectionDateEnd),
    deliveryDateStart: dateOrToday(object.deliveryDateStart),
    deliveryDateEnd: sanitizeBackend(object.deliveryDateEnd),
    publishDate: sanitizeBackend(object.publishDate),
    availableUntil: props.action === QUOTING ? dateOrToday(object.availableUntil) : sanitizeBackend(object.availableUntil),
    date: dateOrToday(object.date),
    truckTires: object.truckTires,
    truckAxis: object.truckAxis,
    truckHeight: object.truckHeight,
    truckWidth: object.truckWidth,
    truckLength: object.truckLength,
    personOriginId: (object.personOrigin || {}).id,
    addressOriginId: (object.addressOrigin || {}).id,
    personDestinationId: (object.personDestination || {}).id,
    addressDestinationId: (object.addressDestination || {}).id,
    serviceTakerId: object.serviceTaker?.id,
    products: (object.products || [{
      quantity: 1
    }]).map(product => {
      return {
        ...product,
        commercialUnitId: (product.commercialUnit || {}).id,
        packingKindId: (product.packingKind || {}).id
      };
    }),
    bodyworks: object.bodyworks,
    vehicleTypes: object.vehicleTypes,
    advanceMethod: (object.offer || {}).advanceMethod || BY_TRAVEL,
    value: (object.offer || {}).value || 0,
    advanceValue: (object.offer || {}).advanceValue,
    advanceUnitaryValue: (object.offer || {}).advanceUnitaryValue
  };
};