import gql from "graphql-tag";
import { referentialAccountSimpleFragment } from "./ReferentialAccountSimple";
export const referentialChartAccountFragment = gql`
  fragment ReferentialChartAccount on ReferentialChartAccount {
    id,
    referentialAccount {
      ...ReferentialAccountSimple
    },
    ancestry,
    parentId,
    classification,
    key,
    normalBalance,
    group,
    accountingMethod,
    referentialAccountId,
    initialNumbers,
    finalNumbers,
    level
  }
  ${referentialAccountSimpleFragment}
`;