import gql from 'graphql-tag';
import { alertTemplateFragment } from '../fragments/AlertTemplate';
export const GQL_ALERT_TEMPLATES_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $alertTemplateFilter: AlertTemplateFilter) {    
    alertTemplatesPaginated(first: $pageSize, after: $cursor, alertTemplateFilter: $alertTemplateFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...AlertTemplate
        }
      }
    }    
  }
  ${alertTemplateFragment}
`;
export const GQL_ALERT_TEMPLATE_STORE = gql`
  mutation StoreAlertTemplate($attributes: AlertTemplateAttributes!) {
    storeAlertTemplate(input: { attributes: $attributes }) {
      alertTemplate {
        ...AlertTemplate
      }
      errors
    }
  }
  ${alertTemplateFragment}
`;