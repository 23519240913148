export const skillParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      event: values.event,
      target: values.target
    }
  };
};
export const skillDefaultBuilder = record => {
  return {
    name: (record || {}).name,
    event: (record || {}).event,
    target: (record || {}).target
  };
};