import gql from "graphql-tag";
import { personSimpleFragment } from 'admin-front';
import { vehicleSimpleFragment } from "fleet-front";
export const simpleFreightBidFragment = gql`
  fragment SimpleFreightBid on FreightBid { 
    id,
    pricingMethod, 
    pricingValue,
    value,
    advanceMethod,
    advanceValue,
    advanceUnitaryValue,
    mileage,
    cargoGrossWeight,
    cargoValue,
    datetime,    
    situation,
    observation,
    freightId,    
    personId,    
    person {
      ...PersonSimple
    },
    vehicleId,    
    vehicle {
      ...VehicleSimple
    },
  }
  ${personSimpleFragment}
  ${vehicleSimpleFragment}
`;