import gql from "graphql-tag";
import { referentialChartAccountFragment } from "./ReferentialChartAccount";
export const referentialAccountFragment = gql`
  fragment ReferentialAccount on ReferentialAccount {
    id,
    name,
    start,
    finish,
    kind,
    classification,
    chartAccount {
      ...ReferentialChartAccount
    }
    referentialChartAccounts {
      ...ReferentialChartAccount
    }
  }
  ${referentialChartAccountFragment}
`;