export const PER_PERIOD = 'per_period';
export const PER_QUANTITY = 'per_quantity';
export const INDETERMINATE = 'indeterminate';
export const SINGLE_FREIGHT = 'single_freight';
export const WITHOUT_ESCORT = 'without_escort';
export const ESCORTED = 'escorted';
export const SAFE_CARGO = 'safe_cargo';
export const DANGEROUS_CARGO = 'dangerous_cargo';
export const UNSENSITIVE_CARGO = 'unsensitive_cargo';
export const SENSITIVE_CARGO = 'sensitive_cargo';
export const ORDINARY_CARGO = 'ordinary_cargo';
export const VALUABLE_CARGO = 'valuable_cargo';
export const CONTRACT_VALIDITY_OPTIONS = [{
  value: PER_PERIOD,
  label: 'Por período'
}, {
  value: PER_QUANTITY,
  label: 'Por quantidade'
}, {
  value: INDETERMINATE,
  label: 'Indeterminado'
}, {
  value: SINGLE_FREIGHT,
  label: 'Frete único',
  disabled: true
}];
export const ESCORT_OPTIONS = [{
  value: ESCORTED,
  label: 'Possui'
}, {
  value: WITHOUT_ESCORT,
  label: 'Não possui'
}];
export const DANGEROUS_OPTIONS = [{
  value: DANGEROUS_CARGO,
  label: 'Sim'
}, {
  value: SAFE_CARGO,
  label: 'Não'
}];
export const SESITIVE_OPTIONS = [{
  value: SENSITIVE_CARGO,
  label: 'Sim'
}, {
  value: UNSENSITIVE_CARGO,
  label: 'Não'
}];
export const VALUABLE_OPTIONS = [{
  value: VALUABLE_CARGO,
  label: 'Sim'
}, {
  value: ORDINARY_CARGO,
  label: 'Não'
}];