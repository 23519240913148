export const PIE_VIEW_ICON = 'path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891';
export const DATE_FORMAT = 'date';
export const STRING_FORMAT = 'string';
export const INTEGER_FORMAT = 'integer';
export const FLOAT_FORMAT = 'float';
export const CURRENCY_FORMAT = 'currency';
export const AREA_CHART_TYPE = 'area';
export const BAR_CHART_TYPE = 'bar';
export const LINE_CHART_TYPE = 'line';
export const PIE_CHART_TYPE = 'pie';
export const DONUT_CHART_TYPE = 'donut';
export const CALENDAR_CHART_TYPE = 'calendar';
export const GAUGE_CHART_TYPE = 'gauge';
export const THERMOMETER_CHART_TYPE = 'thermometer';
export const FUNNEL_CHART_TYPE = 'funnel';
export const HEATMAP_CHART_TYPE = 'heatmap';
export const GRAPH_CHART_TYPE = 'graph';
export const CANDLESTICK_CHART_TYPE = 'candlestick';
export const SOLID_LINE_STYLE_TYPE = 'solid';
export const DOTTED_LINE_STYLE_TYPE = 'dotted';
export const TOP_POSITION = 'top';
export const BOTTOM_POSITION = 'bottom';
export const MAX_HIGHLIGHT_RULE = 'max';
export const MIN_HIGHLIGHT_RULE = 'min';