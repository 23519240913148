import gql from "graphql-tag";
import { personSimpleFragment } from 'admin-front';
import { vehicleSimpleFragment } from "fleet-front";
import { simpleFreightFragment } from "./SimpleFreight";
import { simpleFreightBidFragment } from "./SimpleFreightBid";
export const freightBidFragment = gql`
  fragment FreightBid on FreightBid { 
    id,
    pricingMethod, 
    pricingValue,
    value,
    advanceMethod,
    advanceValue,
    advanceUnitaryValue,
    mileage,
    cargoGrossWeight,
    cargoValue,
    datetime,    
    situation,
    observation,
    freightId,    
    freight { 
      ...SimpleFreight
    }
    personId,    
    person {
      ...PersonSimple
    },
    vehicleId,    
    vehicle {
      ...VehicleSimple
    },
    companyBid {
      ...SimpleFreightBid
    },
    companyBids {
      ...SimpleFreightBid
    },
    lastPersonBid {
      ...SimpleFreightBid
    }
  }
  ${simpleFreightFragment}
  ${personSimpleFragment}
  ${simpleFreightBidFragment}
  ${vehicleSimpleFragment}
`;