import gql from "graphql-tag";
import { addressFragment, personSimpleFragment, companySimpleFragment } from 'admin-front';
import { vehicleBodyworkFragment, vehicleTypeFragment, vehicleSimpleFragment } from 'fleet-front';
import { WaybillFragment } from 'fiscal-front';
import { freightBidFragment } from "./FreightBid";
import { FreightLogbookFragment } from "./FreightLogbook";
import { freightOfferFragment } from "./FreightOffer";
import { freightProductFragment } from "./FreightProduct";
import { freightSuggestedDriverFragment } from "./FreightSuggestedDriver";
import { evaluationFragment } from "./Evaluation";
import { freightDiscountFragment } from "./FreightDiscount";
import { contractSimpleFragment } from "./ContractSimple";
import { freightInsuranceFragment } from "./FreightInsurance";
import { driverFragment } from "./Driver";
import { freighterOperationFragment } from "./FreighterOperation";
export const FreightFragment = gql`
  fragment Freight on Freight  { 
    id,
    transactionId,
    situation,
    tracker,
    priority,
    freightMethod,
    serviceType,
    serviceTakerIdentifier,
    loadingTax,
    unloadingTax,
    toll,
    tollPayingResponsible,
    tollValue,
    tollMethod,
    tollVoucherSupplierId,
    tollPurchaseNumber,
    pricingMethod,
    pricingValue,
    value,
    advanceMethod,
    advanceUnitaryValue,
    advanceValue,
    kind,
    canvas,
    measureType,
    cargoGrossWeight,
    cargoValue,
    description,
    additionalTransportationFeature,
    additionalServiceFeature,
    additionalCargoFeature,
    receiverWithdraw,
    receiverDetail,
    collectionDateStart,
    collectionDateEnd,
    deliveryDateStart,
    deliveryDateEnd,
    publishDate,
    availableUntil,
    date,
    truckTires,
    truckAxis,
    truckHeight,
    truckWidth,
    truckLength,
    mileage,
    loadingPosition,
    unloadingPosition,
    personBid {
      ...FreightBid
    },
    companyBid {
      ...FreightBid
    },
    lastBid {
      ...FreightBid
    },
    receivedBids {
      ...FreightBid
    },
    personOriginId,
    personOrigin {
      ...PersonSimple
    },
    addressOriginId,
    addressOrigin {
      ...Address
    },
    personDestinationId,
    personDestination {
      ...PersonSimple
    },
    addressDestinationId,
    addressDestination {
      ...Address
    },
    companyId,
    company {
      ...CompanySimple
    },
    serviceTakerId,
    serviceTaker {
      ...PersonSimple
    },
    serviceTakerAddressId,
    serviceTakerAddress {
      ...Address
    },
    dispatcherId,
    dispatcher {
      ...PersonSimple
    },
    receiverId,
    receiver {
      ...PersonSimple
    },
    hiredId,
    hired {
      ...PersonSimple
    },
    driverId,
    driver {
      ...FreighterDriver
    },
    vehicleId,    
    vehicle {
      ...VehicleSimple
    },
    vehicleOwnerId,    
    vehicleOwner {
      ...PersonSimple
    },        
    operationId,
    operation {
      ...FreighterOperation
    }
    contractId,
    contract {
      ...ContractSimple
    },
    waybillId,
    waybill {
      ...Waybill
    },
    products {
      ...FreightProduct
    },
    discounts {
      ...FreightDiscount
    },
    bodyworks {
      ...VehicleBodywork
    },
    vehicleTypes {
      ...VehicleType
    }
    offer {
      ...FreightOffer
    },
    offers {
      ...FreightOffer
    },
    currentLogbook {
      ...FreightLogbook
    },
    logbooks {
      ...FreightLogbook
    }
    freightSuggestedDrivers {
      ...FreightSuggestedDriver
    }
    driverEvaluations{
      ...Evaluation
    }
    personOriginEvaluations{
      ...Evaluation
    }
    personDestinationEvaluations{
      ...Evaluation
    }
    insurances {
      ...FreightInsurance
    },
    canDisplaceToLoadingPlace,
    suggestedForDriver,
  }
  ${driverFragment}  
  ${freightBidFragment}  
  ${companySimpleFragment}  
  ${personSimpleFragment}
  ${addressFragment}
  ${evaluationFragment}
  ${freightProductFragment}
  ${freightDiscountFragment}
  ${vehicleBodyworkFragment}
  ${vehicleTypeFragment}  
  ${freightOfferFragment}  
  ${FreightLogbookFragment}
  ${freightSuggestedDriverFragment}
  ${contractSimpleFragment}
  ${freightInsuranceFragment}
  ${freighterOperationFragment}
  ${WaybillFragment}  
  ${vehicleSimpleFragment}
`;