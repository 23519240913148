import gql from "graphql-tag";
import { operationFragment } from 'financial-front';
export const freightDiscountFragment = gql`
  fragment FreightDiscount on FreightDiscount  { 
    id,
    rate,
    value,
    basis,
    calculationMethod,
    financialOperationId,
    financialOperation {
      ...Operation
    }    
  }
  ${operationFragment}
`;