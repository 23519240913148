import gql from "graphql-tag";
import { skillFragment } from "./Skill";
export const evaluationFragment = gql`
  fragment Evaluation on Evaluation { 
    id,
    freighterFreightId,
    freighterSkillId,
    personId,
    avaliation,
    event,
    skill{
      ...Skill
    }
  }
  ${skillFragment}
`;