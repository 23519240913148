import React from "react"
import {
  InstagramOutlined,
  XOutlined,
  FacebookOutlined,
  LinkedinOutlined
} from '@ant-design/icons';
import { Row, Col, Text, Title, Link, Image, GDivider } from 'grifo-components';
import logo from '../assets/images/logo.png';

const Footer = () => {
  return (
    <>
      <Row gutter={16} className="app-footer">
        <Col span={24}>
          <Row gutter={16}>
            <Col span={6}>
              <Link href="https://www.cooperasis.com.br" target="_blank">
                <Image preview={false} src={logo} width={250}/>
              </Link> 
            </Col>

            <Col span={7}>
              <Title level={5}>Recursos</Title>
              <Row gutter={16}>
                <Col span={4}>
                  <Link href="https://blog.cooperasis.com.br/" target="_blank">
                    <Text><small>Blog</small></Text>
                  </Link>
                </Col>

                <Col span={12}>
                  <Link href="https://blog.cooperasis.com.br/" target="_blank">
                    <Text><small>Política de privacidade</small></Text>
                  </Link>
                </Col>

                <Col span={8}>
                  <Link href="https://blog.cooperasis.com.br/" target="_blank">
                    <Text><small>Termo de uso</small></Text>
                  </Link>
                </Col>
              </Row>             
            </Col>

            <Col span={8}>
              <Title level={5}>Conhecimento</Title>
              <Row gutter={16}>
                <Col span={10}>
                  <Link href="https://grifo-sistema.movidesk.com/" target="_blank">
                    <Text><small>Central de atendimento</small></Text>
                  </Link>
                </Col>

                <Col span={10}>
                  <Link href="https://grifo-sistema.movidesk.com/kb" target="_blank">
                    <Text><small>Base de Conhecimento</small></Text>
                  </Link>
                </Col>

                <Col span={4}>
                  <Link href="https://grifo-sistema.movidesk.com/kb/article/352211/atendimento" target="_blank">
                    <Text><small>Ajuda</small></Text>
                  </Link>
                </Col>
              </Row>
            </Col>

            <Col span={3}>
              <Title level={5}>Siga Auriga ERP</Title>
              <Row>
                <Col span={6}>
                  <Link href="https://www.instagram.com/cooperasis/" target="_blank">
                    <InstagramOutlined style={{ fontSize: '24px', color: '#DD2A7B' }}/>
                  </Link>                
                </Col>
                <Col span={6}>
                  <Link href="https://x.com/cooperasis" target="_blank">
                    <XOutlined style={{ fontSize: '24px', color: 'black'}}/>
                  </Link>                
                </Col>
                <Col span={6}>
                  <Link href="https://www.facebook.com/cooperasis" target="_blank">
                    <FacebookOutlined style={{ fontSize: '24px', color: '#0866FF'}}/>
                  </Link>
                </Col>
                <Col span={6}>
                  <Link href="https://www.linkedin.com/showcase/cooperasis" target="_blank">
                    <LinkedinOutlined style={{ fontSize: '24px', color: '#0a66c2'}}/>
                  </Link>
                </Col>
              </Row>
            </Col>         
            
          </Row>
        </Col>     
      </Row>

      <GDivider />
    </>
  );
};

export default Footer;