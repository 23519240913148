import { CURRENCY_FORMAT, HEATMAP_CHART_TYPE } from "../../constants/Charts";
import { getLabelByValue } from "../ListUtil";
import { formatter } from "./GenericChartUtil";
export const buildHeatmapSeries = (seriesData = [], valueParams = {}, xAxisParams = {}, yAxisParams = {}) => {
  const data = seriesData.map(item => {
    return [item[xAxisParams?.field || 'date'], yAxisParams?.fieldDictionary ? getLabelByValue(yAxisParams?.fieldDictionary, item[yAxisParams?.field]) : item[yAxisParams?.field], item[valueParams?.field || 'value'] || '-'];
  });
  return [{
    name: 'Vendas',
    type: HEATMAP_CHART_TYPE,
    data: data,
    label: {
      show: true,
      formatter: (params, index) => {
        return formatter(params.value[2], valueParams?.dataType || CURRENCY_FORMAT);
      }
    },
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
  }];
};