import gql from 'graphql-tag';
import { FreightFragment } from '../fragments/Freight';
export const GQL_AVAILABLE_FREIGHTS = gql`
  query GetFilter($pageSize: Int, $cursor: String, $freightFilter: FreightFilter) {    
    freightsPaginated(first: $pageSize, after: $cursor, freightFilter: $freightFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Freight
        }
      }
    }    
  }
  ${FreightFragment}
`;