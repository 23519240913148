import gql from "graphql-tag";
import { companySimpleFragment } from 'admin-front';
export const travelEventFragment = gql`
  fragment TravelEvent on TravelEvent  { 
    id,
    company {
      ...CompanySimple
    },
    name,
    seriousness,
    description
  }
  ${companySimpleFragment}  
`;