import gql from 'graphql-tag';
import { freightBidFragment } from '../fragments/FreightBid';
export const GQL_FREIGHT_BID_STORE = gql`
  mutation StoreFreightBid($attributes: FreightBidAttributes!) {
    storeFreightBid(input: { attributes: $attributes }) {
      freightBid {
        ...FreightBid
      }
      errors
    }
  }
  ${freightBidFragment}
`;