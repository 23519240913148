import { CHARGED_UP, CLOSURE, DISPLACEMENT_TO_LOADING_PLACE, FINISHED, LOADING, TRAVELING, UNLOADING, WAITING_FOR_LOADING, WAITING_FOR_UNLOADING } from "../constants/freights/FreightFieldsOptions";
export const freightLogbookParamBuilder = (record, values) => {
  const situation = record.currentLogbook?.situation;
  const nextSituation = situation === CLOSURE ? DISPLACEMENT_TO_LOADING_PLACE : situation === DISPLACEMENT_TO_LOADING_PLACE ? WAITING_FOR_LOADING : situation === WAITING_FOR_LOADING ? LOADING : situation === LOADING ? CHARGED_UP : situation === CHARGED_UP ? TRAVELING : situation === TRAVELING ? WAITING_FOR_UNLOADING : situation === WAITING_FOR_UNLOADING ? UNLOADING : FINISHED;
  let quantitiesLoaded = (values.products || []).map(product => {
    return {
      freightProductId: product.id,
      quantity: product.quantityLoaded
    };
  });
  return {
    attributes: {
      freightId: record.id,
      log: values.log,
      situation: nextSituation,
      quantitiesLoaded: quantitiesLoaded
    }
  };
};
export const freightLogbookDefaultBuilder = record => {
  return {
    products: record.products
  };
};