export const ASSET_ACCOUNT = 'asset_account';
export const LIABILITY_ACCOUNT = 'liability_account';
export const EQUITY_ACCOUNT = 'equity_account';
export const INCOME_ACCOUNT = 'income_account';
export const CLEARING_ACCOUNT = 'clearing_account';
export const OTHER = 'other';
export const ACCOUNT_KINDS = [{
  value: ASSET_ACCOUNT,
  label: '01 - Contas do Ativo'
}, {
  value: LIABILITY_ACCOUNT,
  label: '02 - Contas do Passivo'
}, {
  value: EQUITY_ACCOUNT,
  label: '03 - Contas do Patrimônio Líquido'
}, {
  value: INCOME_ACCOUNT,
  label: '04 - Contas de Resultado'
}, {
  value: CLEARING_ACCOUNT,
  label: '05 - Contas de Compensação'
}, {
  value: OTHER,
  label: '09 - Outros'
}];