export const OPENED_BY_PERSON = 'opened_by_person';
export const COUNTER_PROPOSAL_BY_PERSON = 'counter_proposal_by_person';
export const REJECTED_BY_PERSON = 'rejected_by_person';
export const ACCEPTED_BY_PERSON = 'accepted_by_person';
export const DISMISSED_BY_PERSON = 'dismissed_by_person';
export const OPENED_BY_COMPANY = 'opened_by_company';
export const COUNTER_PROPOSAL_BY_COMPANY = 'counter_proposal_by_company';
export const REJECTED_BY_COMPANY = 'rejected_by_company';
export const ACCEPTED_BY_COMPANY = 'accepted_by_company';
export const DISMISSED_BY_COMPANY = 'dismissed_by_company';
export const UNACCEPTED_BY_COMPANY = 'unaccepted_by_company';
export const FREIGHT_BID_SITUATIONS = [{
  value: undefined,
  label: 'Não iniciada'
}, {
  value: OPENED_BY_PERSON,
  label: 'Aberto pelo interessado'
}, {
  value: COUNTER_PROPOSAL_BY_PERSON,
  label: 'Contra proposta do interessado'
}, {
  value: REJECTED_BY_PERSON,
  label: 'Rejeitado pelo interessado'
}, {
  value: ACCEPTED_BY_PERSON,
  label: 'Aceito pelo interessado'
}, {
  value: DISMISSED_BY_PERSON,
  label: 'Dispensado pelo interessado'
}, {
  value: OPENED_BY_COMPANY,
  label: 'Aberto pela empresa'
}, {
  value: COUNTER_PROPOSAL_BY_COMPANY,
  label: 'Contra proposta da empresa'
}, {
  value: REJECTED_BY_COMPANY,
  label: 'Rejeitado pela empresa'
}, {
  value: ACCEPTED_BY_COMPANY,
  label: 'Aceito pela empresa'
}, {
  value: UNACCEPTED_BY_COMPANY,
  label: 'Aceite desfeito pela empresa'
}];