import gql from "graphql-tag";
import { addressFragment, personSimpleFragment } from 'admin-front';
import { vehicleBodyworkFragment, vehicleTypeFragment } from 'fleet-front';
import { FreightLogbookFragment } from "./FreightLogbook";
import { freightOfferFragment } from "./FreightOffer";
import { freightProductFragment } from "./FreightProduct";
import { freightInsuranceFragment } from "./FreightInsurance";
import { simpleFreightBidFragment } from "./SimpleFreightBid";
export const simpleFreightFragment = gql`
  fragment SimpleFreight on Freight  { 
    id,
    transactionId,
    situation,
    tracker,
    priority,
    loadingTax,
    unloadingTax,
    toll,
    kind,
    canvas,
    description,
    collectionDateStart,
    collectionDateEnd,
    deliveryDateStart,
    deliveryDateEnd,
    publishDate,
    availableUntil,
    date,
    truckTires,
    truckAxis,
    truckHeight,
    truckWidth,
    truckLength,
    value,
    mileage, 
    cargoValue,
    cargoGrossWeight,
    personOrigin {
      ...PersonSimple
    },
    addressOrigin {
      ...Address
    },
    personDestination {
      ...PersonSimple
    },
    addressDestination {
      ...Address
    },
    serviceTaker {
      ...PersonSimple
    },
    serviceTakerAddress {
      ...Address
    },    
    products {
      ...FreightProduct
    },
    bodyworks {
      ...VehicleBodywork
    },
    vehicleTypes {
      ...VehicleType
    }
    offer {
      ...FreightOffer
    },
    offers {
      ...FreightOffer
    },
    currentLogbook {
      ...FreightLogbook
    },
    insurances {
      ...FreightInsurance
    },
    companyBid {
      ...SimpleFreightBid
    },
    lastBid {
      ...SimpleFreightBid
    },
    personBid {
      ...SimpleFreightBid
    },
    canDisplaceToLoadingPlace,
    suggestedForDriver,
  }
  ${personSimpleFragment}
  ${addressFragment}
  ${freightProductFragment}
  ${vehicleBodyworkFragment}
  ${vehicleTypeFragment}  
  ${freightOfferFragment}  
  ${FreightLogbookFragment}
  ${freightInsuranceFragment}
  ${simpleFreightBidFragment}
`;