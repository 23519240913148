import { DO_NOT_PROTEST, TO_PAY } from "../constants/operations/OperationsFieldsOptions";
import { sanitize, getSessionInfo } from 'grifo-components';
export const operationParamBuilder = (record, values) => {
  let params = sanitize(values, ["resultCenter"]);
  return {
    attributes: {
      id: record ? record.id : null,
      name: params.name,
      kind: params.kind,
      classification: params.classification,
      generatesMovement: params.generatesMovement,
      parentId: params.parentId,
      writeOffId: params.writeOffId,
      earlyPaymentOperationId: params.earlyPaymentOperationId,
      earlyPaymentDeduction: params.earlyPaymentDeduction,
      counterpartId: params.counterpartId,
      resultCenters: params.resultCenters ? params.resultCenters.map(object => ({
        ...object,
        companyId: getSessionInfo().company.id
      })) : undefined,
      internal: values.internal || false,
      canPayDefaulters: values.canPayDefaulters || false,
      daysAfterOverdueToExpiredTitle: params.daysAfterOverdueToExpiredTitle || 0,
      latePaymentInterest: params.latePaymentInterest,
      latePaymentInterestKind: params.latePaymentInterestKind,
      latePaymentInterestOperationId: params.latePaymentInterestOperationId,
      latePaymentFineOperationId: params.latePaymentFineOperationId,
      latePaymentFine: params.latePaymentFine,
      acceptPaymentAfterDuedate: params.acceptPaymentAfterDuedate,
      protestCode: params.protestCode,
      daysToProtest: params.daysToProtest
    }
  };
};
export const operationDefaultBuilder = record => {
  let object = record || {};
  let acceptPaymentAfterDuedate = object.acceptPaymentAfterDuedate == undefined ? true : object.acceptPaymentAfterDuedate;
  let generatesMovement = object.generatesMovement == undefined ? true : object.generatesMovement;
  return {
    name: object.name,
    kind: object.kind || TO_PAY,
    classification: object.classification,
    generatesMovement: generatesMovement,
    parentId: object.parentId,
    writeOffId: object.writeOffId,
    earlyPaymentOperationId: object.earlyPaymentOperationId,
    earlyPaymentDeduction: object.earlyPaymentDeduction || 0,
    counterpartId: object.counterpartId,
    resultCenters: object.resultCenters,
    internal: object.internal || false,
    canPayDefaulters: object.canPayDefaulters,
    daysAfterOverdueToExpiredTitle: object.daysAfterOverdueToExpiredTitle || 3,
    latePaymentInterest: object.latePaymentInterest || 0,
    latePaymentInterestKind: object.latePaymentInterestKind,
    latePaymentInterestOperationId: object.latePaymentInterestOperationId,
    latePaymentFineOperationId: object.latePaymentFineOperationId,
    latePaymentFine: object.latePaymentFine || 0,
    acceptPaymentAfterDuedate: acceptPaymentAfterDuedate || true,
    protestCode: object.protestCode || DO_NOT_PROTEST,
    daysToProtest: object.daysToProtest || 0
  };
};