import gql from "graphql-tag";
import { operationFragment } from "financial-front";
import { personSimpleFragment } from "admin-front";
import { freighterOperationDiscountFragment } from "./FreighterOperationDiscount";
import { fiscalOperationFragment } from "fiscal-front";
export const freighterOperationSimpleFragment = gql`
  fragment FreighterOperationSimple on FreighterOperation { 
    id,
    freightMethod,
    name,
    pricingMethod,
    advanceMethod,
    tollMethod,
    pricingValue,
    advanceValue,
    tollPayingResponsible,
    parentId,    
    fiscalOperationId,
    fiscalOperation {
      ...FiscalOperation
    },
    hiredPaymentId,
    hiredPayment {
      ...Operation
    },
    hiredAdvanceId,
    hiredAdvance {
      ...Operation
    },
    hiredAdvanceReceiveId,
    hiredAdvanceReceive {
      ...Operation
    },
    tollPaymentId,
    tollPayment {
      ...Operation
    },
    tollReceiveId,
    tollReceive {
      ...Operation
    },
    tollVoucherSupplierId,
    tollVoucherSupplier {
      ...PersonSimple
    },    
    discounts {
      ...FreighterOperationDiscount
    },
    discardedAt
  }
  ${fiscalOperationFragment}
  ${operationFragment}  
  ${personSimpleFragment}  
  ${freighterOperationDiscountFragment}  
`;