import gql from "graphql-tag";
import { commercialUnitFragment, packingKindFragment, productFragment } from 'stock-front';
export const contractProductFragment = gql`
  fragment ContractProduct on ContractProduct  { 
    id,
    quantity,
    width,
    length,
    weight,    
    height,    
    escort,
    dangerousCargo,
    sensitiveCargo,
    valuableCargo,
    totalQuantityDelivered,
    prevalent,
    measureType,
    unitForTransport,
    product {
      ...Product
    }
    commercialUnit {
      ...CommercialUnit
    }
    packingKind {
      ...PackingKind
    }   
  }
  ${productFragment}
  ${commercialUnitFragment}  
  ${packingKindFragment}    
`;