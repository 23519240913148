import { LOADING } from "../constants/freights/FreightFieldsOptions";
import { CUSTOMER } from "../constants/skills/SkillOptions";
export const evaluationParamBuilder = (record, values, freight) => {
  const evaluations = [];
  let evaluatedPerson;
  if (freight.exceptTarget === CUSTOMER) {
    evaluatedPerson = freight.driver?.person?.id;
  }
  Object.keys(values).map(key => {
    return Object.keys(values[key]).map(event => {
      if (values[key][event].avaliation === undefined) return;
      return evaluations.push({
        id: (freight.evaluations || {})[key]?.[event]?.id,
        avaliation: values[key][event].avaliation,
        freighterSkillId: key,
        freighterFreightId: freight.id,
        personId: evaluatedPerson || (event === LOADING ? freight.personOrigin?.id : freight.personDestination?.id),
        event: event
      });
    });
  });
  return {
    attributes: evaluations
  };
};
export const evaluationDefaultBuilder = record => {
  const object = record || {};
  return object.evaluations;
};