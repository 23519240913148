import gql from 'graphql-tag';
import { FreightFragment } from '../fragments/Freight';
export const GQL_FREIGHTS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $freightFilter: FreightFilter) {    
    freightsPaginated(first: $pageSize, after: $cursor, freightFilter: $freightFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Freight
        }
      }
    }    
  }
  ${FreightFragment}
`;
export const GQL_FREIGHTS_BY_USER_SERVICE_TAKER = gql`
  query GetByUserServiceTakerFilter($byUserServiceTakerFilter: FreightByUserServiceTakerFilter) {
    freights(byUserServiceTakerFilter: $byUserServiceTakerFilter) {
      ...Freight
    }
  }
  ${FreightFragment}
`;
export const GQL_FREIGHTS_BY_ORIGIN_OR_DESTINATION = gql`
  query GetByOriginOrDestinationFilter($byOriginOrDestinationFilter: FreightByOriginOrDestinationFilter) {
    freights(byOriginOrDestinationFilter: $byOriginOrDestinationFilter) {
      ...Freight
    }
  }
  ${FreightFragment}
`;
export const GQL_FREIGHTS_BY_PERSON_LIST = gql`
  query GetByPersonFilter($pageSize: Int, $cursor: String, $byPersonFilter: FreightByPersonFilter, $freightFilter: FreightFilter) {    
    freightsPaginated(first: $pageSize, after: $cursor, byPersonFilter: $byPersonFilter, freightFilter: $freightFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Freight
        }
      }
    }    
  }
  ${FreightFragment}
`;
export const GQL_FREIGHT_STORE = gql`
  mutation StoreFreight($attributes: FreightAttributes!) {
    storeFreight(input: { attributes: $attributes }) {
      freight {
        ...Freight
      }
      errors
    }
  }
  ${FreightFragment}
`;
export const GQL_FREIGHT_SITUATION = gql`
  mutation StoreFreightLogbook($attributes: FreightLogbookAttributes! ) { 
    storeFreightLogbook(input: {attributes: $attributes}) {
      id,
      errors
    }
  }
`;
export const GQL_FREIGHT_POSITION = gql`
  mutation StoreFreightPosition($attributes: FreightPositionAttributes! ) { 
    storeFreightPosition(input: {attributes: $attributes}) {
      id,
      errors
    }
  }
`;
export const GQL_STORE_FREIGHT_QUANTITY = gql`
  mutation StoreFreightQuantity($attributes: FreightQuantityAttributes!) { 
    storeFreightQuantity(input: {attributes: $attributes}) {
      id,
      errors
    }
  }
`;
export const GQL_WAYBILL_FROM_FREIGHT_STORE = gql`
  mutation StoreWaybillFromFreight($attributes: WaybillFromFreightAttributes!) {
    storeWaybillFromFreight(input: {attributes: $attributes}) { 
      freight {
        ...Freight,
      }, 
      errors 
    } 
  }
  ${FreightFragment}
`;