import gql from "graphql-tag";
import { fleetPersonSimpleFragment } from "fleet-front";
export const driverFragment = gql`
  fragment FreighterDriver on FreighterDriver  { 
    id,
    person {
      ...FleetPersonSimple
    },
    discardedAt
  }
  ${fleetPersonSimpleFragment}
`;