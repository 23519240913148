import { FISCO, GENERAL } from "../constants/additionalInfos/AdditionalInfoOptions";
export const invoiceInfoListDefaultBuilder = record => {
  const object = record || {};
  return {
    infos: object || []
  };
};
export const invoiceInfoDefaultBuilder = form => {
  let infos = form.getFieldsValue().infos;
  infos[infos.length - 1] = {
    target: FISCO,
    origin: GENERAL
  };
  form.setFieldsValue({
    infos: infos
  });
};