export const alertTemplateParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      travelEvent: parseInt(values.travelEvent.travelEvent),
      text: values.text
    }
  };
};
export const alertTemplateDefaultBuilder = record => {
  let data = record || {};
  return {
    travelEvent: data.travelEvent,
    text: data.text
  };
};