import gql from 'graphql-tag';
import { addressFragment, phoneFragment, userStoreSimpleFragment, contactFragment } from 'admin-front';
import { personFiscalInfoFragment, personCnaeFragment, personFiscalStFragment, personFiscalStartFragment, stateRegistrationFragment, certificateFragment, personDocumentFragment } from 'fiscal-front';
import { personBankingFragment } from 'financial-front';
export const accountingPersonFragment = gql`
  fragment AccountingPerson on AccountingPerson {
    id,
    name,
    tradeName,
    document,
    naturalRegistration,
    naturalRegistrationStateId,
    naturalRegistrationAgencyId,
    photo64,
    email,
    gender,
    birth,
    closure,
    cadunicoCode,
    nisCode,
    discardedAt,
    contributeIcms,
    municipalRegistration,
    suframaRegistration,
    taxRegime,
    nfceToken,
    nfceId,
    environment,
    issueMode,
    producer,
    stateRegistration,
    scpIndicator,
    csllBasis,
    apurationPeriod,
    stockAvaliationMethod,
    presidentQualification,
    spedFrameworkProfile,
    icmsTaxableObligationCode,
    accountingActivity,
    regimeKind,
    creditApropriationKind,
    contributionKind,
    cumulativeRegime,
    specialSituation,
    specialSituationDate,
    bookNumber,
    nireCode,
    industryActivity,
    industryClassification,
    stateRegistrations {
      ...StateRegistration
    },
    juridical,
    natural,    
    mainAddress {
      ...Address
    },
    addresses {
      ...Address
    },
    phone {
      ...Phone
    },
    phones {
      ...Phone
    },    
    user {
      ...UserStoreSimple
    },
    ofCompany {
      id,
    }
    contacts {
      ...Contact
    }
    cnaes {
      ...PersonCnae
    },
    sts {
      ...PersonFiscalSt
    },
    infos {
      ...PersonFiscalInfo
    },
    currentInfos {
      ...PersonFiscalInfo
    },    
    fiscalStarts {
      ...PersonFiscalStart
    },
    certificate {
      ...Certificate
    },
    personBankings {
      ...PersonBanking
    },
    personDocuments {
      ...FiscalPersonDocument
    }
  }
  ${addressFragment}
  ${phoneFragment}
  ${userStoreSimpleFragment}
  ${contactFragment}
  ${personCnaeFragment}
  ${personFiscalStFragment}
  ${personFiscalInfoFragment}
  ${personFiscalStartFragment}
  ${stateRegistrationFragment}
  ${certificateFragment}
  ${personBankingFragment}
  ${personDocumentFragment}
`;