import { today } from 'grifo-components';
import { RESPONSIBLE } from "../constants/CompanyAccountantOptions";
export const accountantDefaultBuilder = form => {
  let companyAccountants = form.getFieldsValue().companyAccountants || [];
  const index = companyAccountants.length - 1;
  companyAccountants[index] = {
    start: today(),
    profile: RESPONSIBLE,
    active: true
  };
  form.setFieldsValue({
    companyAccountants: companyAccountants
  });
};