export const ECF_NON_PARTICIPANT = 'ECF_non_participant';
export const ECF_PARTICIPANT = 'ECF_participant';
export const ECF_SCP = 'ECF_SCP';
export const NINE = 'nine';
export const SEVENTEEN = 'seventeen';
export const TWENTY = 'twnety';
export const QUARTERLY = 'quarterly';
export const YEARLY = 'yearly';
export const CMP = 'CMP';
export const PEPS = 'PEPS';
export const STOCK_ARBITRATION = 'stock_arbitration';
export const STOCK_SPECIFIC = 'stock_specific';
export const NET_REALIZABLE = 'net_realizable';
export const PERIODIC = 'periodic';
export const OTHER = 'other_method';
export const VOID = 'void';
export const SHAREHOLDER = 'shareholder';
export const PARTNER = 'partner';
export const HOLDER = 'holder';
export const ADM_NO_BOND = 'adm_no_bond';
export const DIRECTOR_NO_BOND = 'director_no_bond';
export const PR_NO_BOND = 'pr_no_bond';
export const ADM_BOND = 'adm_bond';
export const COUNSELOR = 'counselor';
export const DIRECTOR_BOND = 'director_bond';
export const PR_BOND = 'pr_bond';
export const A = 'A';
export const B = 'B';
export const C = 'C';
export const ICMS_TO_TAX = 'icms_to_tax';
export const ICMS_ST_ENTRY = 'icms_st_entry';
export const ICMS_ST_INNER_STATE = 'icms_st_inner_state';
export const ANTECIPATION_DIFFERENTIAL_ST = 'anticipation_differential_st';
export const ANTECIPATION_ICMS_IMPORT = 'anticipation_icms_import';
export const ANTECIPATION_TAX = 'anticipation_tax';
export const FUNCEP = 'FUNCEP';
export const OTHER_ICMS_OBLIGATION = 'other_icms_obligation';
export const ICMS_ST_OUTER_STATE = 'icms_st_outer_state';
export const INDUSTRIAL = 'industrial';
export const SERVICES = 'services';
export const COMMERCIAL = 'commercial';
export const FINANCIAL = 'financial';
export const REAL_ESTATE = 'real_estate';
export const OTHERS = 'other_activity';
export const NON_CUMULATIVE = 'non_cumulative';
export const CUMULATIVE = 'cumulative';
export const BOTH = 'both';
export const DIRECT = 'direct';
export const PROPORTIONAL = 'proportional';
export const BASIC = 'basic';
export const SPECIFIC = 'specific';
export const CASHIER_CONSOLIDATE = 'cashier_consolidate';
export const COMPETENCE_CONSOLIDATE = 'competence_consolidate';
export const COMPETENCE_DETAILED = 'competence_detailed';
export const NORMAL = 'normal';
export const EXTINCTION = 'extinction';
export const FUSION = 'fusion';
export const INCORPORATED = 'incorporated';
export const DEVELOPER = 'developer';
export const TOTAL_SPINOFF = 'total_spinoff';
export const PARTIAL_SPINOFF = 'partial_spinoff';
export const EXEMPT = 'exempt';
export const SN_INCLUSION = 'sn_inclusion';
export const INDUSTRY_OR_COMPARABLE = 'industry_or_comparable';
export const NON_INDUSTRY = 'non_industry';
export const INDUSTRY_TRANSFORMATION = 'industry_transformation';
export const INDUSTRY_ENRICHMENT = 'industry_enrichment';
export const INDUSTRY_ASSEMBLY = 'industry_assembly';
export const INDUSTRY_PACKAGING = 'industry_packaging';
export const INDUSTRY_RENOVATION = 'industry_renovation';
export const EQUATED_TO_INDUSTRY_BY_OPTION = 'equated_to_industry_by_option';
export const EQUATED_TO_INDUSTRY_BY_IMPORTATION = 'equated_to_industry_by_importation';
export const EQUATED_TO_INDUSTRY_BY_LAW = 'equated_to_industry_by_law';
export const EQUATED_TO_INDUSTRY_BY_OTHER = 'equated_to_industry_by_other';
export const INDUSTRY_OTHERS = 'industry_others';
export const SCP_INDICATOR_OPTIONS = [{
  value: ECF_NON_PARTICIPANT,
  label: 'Não participante de SCP'
}, {
  value: ECF_PARTICIPANT,
  label: 'Participante de SCP'
}, {
  value: ECF_SCP,
  label: 'ECF da SCP'
}];
export const CSLL_BASIS_OPTIONS = [{
  value: NINE,
  label: '9%'
}, {
  value: SEVENTEEN,
  label: '17%'
}, {
  value: TWENTY,
  label: '20%'
}];
export const APURATION_PERIOD_OPTIONS = [{
  value: QUARTERLY,
  label: 'Trimestral'
}, {
  value: YEARLY,
  label: 'Anual'
}];
export const STOCK_AVALIATION_METHOD_OPTIONS = [{
  value: CMP,
  label: 'CMP - Custo médio'
}, {
  value: PEPS,
  label: 'PEPS - Primeiro que entra primeiro que sai'
}, {
  value: STOCK_ARBITRATION,
  label: 'Arbitrário'
}, {
  value: STOCK_SPECIFIC,
  label: 'Específico'
}, {
  value: NET_REALIZABLE,
  label: 'Líquido Realizável'
}, {
  value: PERIODIC,
  label: 'Periódico'
}, {
  value: OTHER,
  label: 'Outro'
}, {
  value: VOID,
  label: 'Nenhum'
}];
export const PRESIDENT_QUALIFICATION_OPTIONS = [{
  value: SHAREHOLDER,
  label: 'Acionista Pessoa física no Brasil'
}, {
  value: PARTNER,
  label: 'Sócio Pessoa física no Brasil'
}, {
  value: HOLDER,
  label: 'Titular'
}, {
  value: ADM_NO_BOND,
  label: 'Administrador sem vínculo'
}, {
  value: DIRECTOR_NO_BOND,
  label: 'Diretor sem vínculo'
}, {
  value: PR_NO_BOND,
  label: 'Presidente sem vínculo'
}, {
  value: ADM_BOND,
  label: 'Administrador com vínculo'
}, {
  value: COUNSELOR,
  label: 'Conselheiro de Administração ou Fiscal'
}, {
  value: DIRECTOR_BOND,
  label: 'Diretor com vínculo'
}, {
  value: PR_BOND,
  label: 'Presidente com vínculo'
}];
export const SPED_FRAMEWORK_PROFILE_OPTIONS = [{
  value: A,
  label: 'A'
}, {
  value: B,
  label: 'B'
}, {
  value: C,
  label: 'C'
}];
export const ICMS_TAXABLE_OBLIGATION_CODE_OPTIONS = [{
  value: ICMS_TO_TAX,
  label: 'ICMS a recolher'
}, {
  value: ICMS_ST_ENTRY,
  label: 'ICMS ST pelas entradas'
}, {
  value: ICMS_ST_INNER_STATE,
  label: 'ICMS ST saídas para o mesmo estado'
}, {
  value: ANTECIPATION_DIFFERENTIAL_ST,
  label: 'Antecipação do diferencial de alíquotas ICMS'
}, {
  value: ANTECIPATION_ICMS_IMPORT,
  label: 'Antecipação ICMS de importação'
}, {
  value: ANTECIPATION_TAX,
  label: 'Antecipação tributária'
}, {
  value: FUNCEP,
  label: 'ICMS FUNCEP'
}, {
  value: OTHER_ICMS_OBLIGATION,
  label: 'Outras obrigações ICMS'
}, {
  value: ICMS_ST_OUTER_STATE,
  label: 'ICMS ST saídas para outros estados'
}];
export const ACCOUNTING_ACTIVITY_OPTIONS = [{
  value: INDUSTRIAL,
  label: 'Industrial'
}, {
  value: SERVICES,
  label: 'Serviços'
}, {
  value: COMMERCIAL,
  label: 'Comércio'
}, {
  value: FINANCIAL,
  label: 'Financeiro'
}, {
  value: REAL_ESTATE,
  label: 'Imobiliário'
}, {
  value: OTHERS,
  label: 'Outros'
}];
export const REGIME_KIND_OPTIONS = [{
  value: NON_CUMULATIVE,
  label: 'Não cumulativo'
}, {
  value: CUMULATIVE,
  label: 'Cumulativo'
}, {
  value: BOTH,
  label: 'Ambos'
}];
export const CREDIT_APROPRIATION_KIND_OPTIONS = [{
  value: DIRECT,
  label: 'Direto'
}, {
  value: PROPORTIONAL,
  label: 'Proporcional'
}];
export const CONTRIBUTION_KIND_OPTIONS = [{
  value: BASIC,
  label: 'Básico'
}, {
  value: SPECIFIC,
  label: 'Específico'
}];
export const CUMULATIVE_REGIME_OPTIONS = [{
  value: CASHIER_CONSOLIDATE,
  label: 'Caixa - Consolidado'
}, {
  value: COMPETENCE_CONSOLIDATE,
  label: 'Competência - Consolidado'
}, {
  value: COMPETENCE_DETAILED,
  label: 'Competência - Detalhado'
}];
export const SPECIAL_SITUATION_OPTIONS = [{
  value: NORMAL,
  label: 'Normal'
}, {
  value: EXTINCTION,
  label: 'Extinção'
}, {
  value: FUSION,
  label: 'Fusão'
}, {
  value: INCORPORATED,
  label: 'Incorporada'
}, {
  value: DEVELOPER,
  label: 'Incorporadora'
}, {
  value: TOTAL_SPINOFF,
  label: 'Cisão Total'
}, {
  value: PARTIAL_SPINOFF,
  label: 'Cisão Parcial'
}, {
  value: EXEMPT,
  label: 'Desenquadramento do Imune/Isenta'
}, {
  value: SN_INCLUSION,
  label: 'Inclusão Simples Nacional'
}];
export const INDUSTRY_ACTIVITY_OPTIONS = [{
  value: INDUSTRY_OR_COMPARABLE,
  label: 'Industrial ou Equiparado a Industrial'
}, {
  value: NON_INDUSTRY,
  label: 'Não é indústria'
}];
export const INDUSTRY_CLASSIFICATION_OPTIONS = [{
  value: INDUSTRY_TRANSFORMATION,
  label: 'Industrial - Transformação'
}, {
  value: INDUSTRY_ENRICHMENT,
  label: 'Industrial - Beneficiamento'
}, {
  value: INDUSTRY_ASSEMBLY,
  label: 'Industrial - Montagem'
}, {
  value: INDUSTRY_PACKAGING,
  label: 'Industrial - Acondicionamento ou Reacondicionamento'
}, {
  value: INDUSTRY_RENOVATION,
  label: 'Industrial - Renovação ou Reacondicionamento'
}, {
  value: EQUATED_TO_INDUSTRY_BY_OPTION,
  label: 'Equiparado a industrial - Por opção'
}, {
  value: EQUATED_TO_INDUSTRY_BY_IMPORTATION,
  label: 'Equiparado a industrial - Importação Direta'
}, {
  value: EQUATED_TO_INDUSTRY_BY_LAW,
  label: 'Equiparado a industrial - Por lei específica'
}, {
  value: EQUATED_TO_INDUSTRY_BY_OTHER,
  label: 'Equiparado a industrial - Não enquadrado nos códigos 05, 06 ou 07'
}, {
  value: INDUSTRY_OTHERS,
  label: 'Outros'
}];