import gql from "graphql-tag";
import { travelEventFragment } from "./TravelEvent";
export const alertTemplateFragment = gql`
  fragment AlertTemplate on AlertTemplate  { 
    id,
    travelEvent {
      ...TravelEvent
    },
    text,
  }
  ${travelEventFragment}  
`;