import gql from 'graphql-tag';
import { companyAccountantFragment } from './CompanyAccountant';
import { accountingPersonFragment } from './Person';
export const accountingCompanyFragment = gql`
  fragment AccountingCompany on AccountingCompany {
    id,
    kind,
    name,
    tradeName,
    isHeadOffice,
    isCooperative,
    stateIcmsApurationCode,
    stateIcmsExpirationDay,
    accountingEntryDeadlineDay,
    person {
      ...AccountingPerson
    },
    companyAccountants {
      ...CompanyAccountant
    }
  }
  ${accountingPersonFragment}
  ${companyAccountantFragment}
`;