import { waybillDefaultBuilder, waybillParamBuilder } from 'fiscal-front';
export const waybillFromFreightParamBuilder = (record, values) => {
  delete record.description;
  let waybillValues = {
    waybillAttributes: waybillParamBuilder(record, values).attributes
  };
  waybillValues.waybillAttributes.id = undefined;
  const freightValues = {
    freightId: values.freightId
  };
  return {
    attributes: {
      ...freightValues,
      ...waybillValues
    }
  };
};
export const waybillFromFreightDefaultBuilder = (record, props = {}) => {
  let object = record || {};
  const freightValues = {
    freightId: object.id
  };
  const waybillValues = convertFreightToWaybill(object, props);
  return {
    ...freightValues,
    ...waybillValues
  };
};
export const convertFreightToWaybill = (record, props = {}) => {
  let object = record || {};
  const params = Object.assign({}, object);
  params.operationId = object.operation?.fiscalOperationId;
  params.operation = object.operation?.fiscalOperation;
  products: (params.products || []).map((object, index) => {
    delete object.quantityLoaded;
    delete object.quantityUnloaded;
    delete object.width;
    delete object.length;
    delete object.height;
    delete object.escort;
    delete object.dangerousCargo;
    delete object.sensitiveCargo;
    delete object.valuableCargo;
    delete object.contractProductId;
    return {
      ...object,
      documents: [{
        quantity: object.quantity
      }]
    };
  });
  return waybillDefaultBuilder(params, props);
};