import gql from 'graphql-tag';
import { skillFragment } from '../fragments/Skill';
export const GQL_SKILLS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String) {    
    skillsPaginated(first: $pageSize, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Skill
        }
      }
    }    
  }
  ${skillFragment}
`;
export const GQL_SKILL_STORE = gql`
  mutation StoreSkill($attributes: SkillAttributes!) {
    storeSkill(input: { attributes: $attributes }) {
      skill {
        ...Skill
      }
      errors
    }
  }
  ${skillFragment}
`;
export const GQL_INACTIVATE_SKILL = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: { id: $id, className: "Freighter::Skill" }) {
      id,
      discardedAt
    }
  }
`;
export const GQL_SKILL_EXCEPT_TARGET = gql`
  query GetByTargetExcept($skillFilter: SkillFilter) {    
    skills(skillFilter: $skillFilter) { 
      ...Skill
    }
  }
  ${skillFragment}
`;