import { dateOrToday, sanitizeBackend, cloneArray, TON } from 'grifo-components';
import { COMPLETE, LOADING, LOADING_FREE, QUANTITY_LOADED, QUANTITY_UNLOADED, QUOTING, SCHEDULING, SERVICE_TAKER, UNLOADING_FREE, WITHOUT_TOLL, WITHOUT_TOLL_VOUCHER } from "../constants/freights/FreightFieldsOptions";
import { defaultBuilder, paramBuilder } from "./FreightCommonBuilder";
export const freightParamBuilder = (record, values) => {
  const freightValues = {
    contractId: record?.contractId || values?.contractId,
    transactionId: values.transactionId,
    situation: values.action || values.situation || record.situation,
    loadingTax: values.loadingTax,
    unloadingTax: values.unloadingTax,
    toll: values.toll,
    tollPayingResponsible: values.tollPayingResponsible,
    tollValue: values.tollValue,
    tollMethod: values.tollMethod,
    tollVoucherSupplierId: values.tollVoucherSupplierId,
    tollPurchaseNumber: values.tollPurchaseNumber,
    kind: values.kind,
    collectionDateStart: values.collectionDateStart ? values.collectionDateStart : undefined,
    collectionDateEnd: values.collectionDateEnd ? values.collectionDateEnd : undefined,
    deliveryDateStart: values.deliveryDateStart ? values.deliveryDateStart : undefined,
    deliveryDateEnd: values.deliveryDateEnd ? values.deliveryDateEnd : undefined,
    publishDate: values.publishDate ? values.publishDate : undefined,
    availableUntil: values.availableUntil ? values.availableUntil : undefined,
    date: values.date ? values.date : undefined,
    mileage: values.mileage,
    measureType: values.measureType,
    cargoGrossWeight: values.cargoGrossWeight,
    cargoValue: values.cargoValue,
    value: values.value,
    advanceValue: values.advanceValue,
    freightSuggestedDrivers: values.freightSuggestedDrivers || [],
    discounts: (values.discounts || []).map((discount, index) => {
      delete discount.financialOperation;
      discount.basis = discount.basis || 0;
      return {
        ...discount
      };
    }),
    offer: {
      pricingMethod: values.pricingMethod,
      pricingValue: values.pricingValue,
      value: values.value,
      advanceMethod: values.advanceMethod,
      advanceUnitaryValue: values.advanceUnitaryValue,
      advanceValue: values.advanceValue,
      mileage: values.mileage,
      cargoGrossWeight: values.cargoGrossWeight,
      cargoValue: values.cargoValue
    }
  };
  const commonValues = paramBuilder(record, values);
  return {
    attributes: {
      ...freightValues,
      ...commonValues
    }
  };
};
export const freightDefaultBuilder = (record, props = {}) => {
  const object = record || {};
  const freightValues = {
    action: props.action,
    contractId: object.contractId,
    transactionId: object.transactionId,
    situation: object.situation || SCHEDULING,
    loadingTax: object.loadingTax || LOADING_FREE,
    unloadingTax: object.unloadingTax || UNLOADING_FREE,
    toll: object.toll || WITHOUT_TOLL,
    tollPayingResponsible: object.tollPayingResponsible || SERVICE_TAKER,
    tollValue: object.tollValue || 0,
    tollMethod: object.tollMethod || WITHOUT_TOLL_VOUCHER,
    tollVoucherSupplierId: object.tollVoucherSupplierId,
    tollPurchaseNumber: object.tollPurchaseNumber,
    kind: object.kind || COMPLETE,
    measureType: object.measureType || TON,
    cargoGrossWeight: object.cargoGrossWeight,
    cargoValue: object.cargoValue || 0,
    value: object.value || 0,
    advanceValue: object.advanceValue || 0,
    collectionDateStart: dateOrToday(object.collectionDateStart),
    collectionDateEnd: sanitizeBackend(object.collectionDateEnd),
    deliveryDateStart: dateOrToday(object.deliveryDateStart),
    deliveryDateEnd: sanitizeBackend(object.deliveryDateEnd),
    publishDate: sanitizeBackend(object.publishDate),
    availableUntil: props.action === QUOTING ? dateOrToday(object.availableUntil) : sanitizeBackend(object.availableUntil),
    date: dateOrToday(object.date),
    value: object.value,
    advanceValue: object.advanceValue,
    freightSuggestedDrivers: object.freightSuggestedDrivers || [],
    mileage: object.mileage || 0,
    discounts: (object.discounts || []).map(discount => {
      return {
        ...discount
      };
    })
  };
  const commonValues = defaultBuilder(object);
  return {
    ...freightValues,
    ...commonValues
  };
};
export const freightQuantityParamBuilder = (record, values) => {
  let quantitiesLoaded = Object.entries(values).map(object => {
    return {
      freightProductId: object[0],
      quantity: object[1]?.quantityLoaded
    };
  });
  return {
    attributes: {
      freightId: record.id,
      attributeName: record.situation === LOADING ? QUANTITY_LOADED : QUANTITY_UNLOADED,
      quantitiesLoaded: quantitiesLoaded
    }
  };
};
export const freightQuantityDefaultBuilder = record => {
  return record;
};
export const buildFreightFromContract = contract => {
  if (!contract) return;
  let freight = cloneArray(contract);
  freight['id'] = undefined;
  freight['contractId'] = contract.id;
  freight['collectionDateStart'] = contract.start;
  freight['deliveryDateStart'] = contract.finish;
  freight.products = freight.products.map(product => {
    let contractProductId = product.id;
    delete product.id;
    delete product.totalQuantityDelivered;
    return {
      ...product,
      unitForTransport: (product.commercialUnit || {}).unitForTransport,
      commercialUnitId: (product.commercialUnit || {}).id,
      packingKindId: (product.packingKind || {}).id,
      contractProductId: contractProductId
    };
  });
  freight.insurances = freight.insurances.map(insurance => {
    delete insurance.id;
    return {
      ...insurance
    };
  });
  return freight;
};