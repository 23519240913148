import { LOGISTIC, CONFIG } from 'grifo-components';
export const buildFeatures = () => {
  const logisticPath = "/" + LOGISTIC;
  const logisticConfigPath = logisticPath + '/' + CONFIG;
  const logisticConfigRoutes = [{
    ordination: '09.07.01',
    name: 'Operações',
    key: logisticConfigPath + "/operation",
    routePath: logisticConfigPath + "/operation",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "OperationsPage",
      import_path: "containers/operation/OperationsPage"
    }
  }, {
    ordination: '09.07.02',
    name: 'Habilidades a avaliar',
    key: logisticConfigPath + "/skills_to_rate",
    routePath: logisticConfigPath + "/skills_to_rate",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "SkillsPage",
      import_path: "containers/skills/SkillsPage"
    }
  }, {
    ordination: '09.07.03',
    name: 'Eventos de viagem',
    key: logisticConfigPath + "/travel_event",
    routePath: logisticConfigPath + "/travel_event",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "TravelEventsPage",
      import_path: "containers/travelEvent/TravelEventsPage"
    }
  }, {
    ordination: '09.07.04',
    name: 'Modelos de alerta',
    key: logisticConfigPath + "/alert_template",
    routePath: logisticConfigPath + "/alert_template",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "AlertTemplatesPage",
      import_path: "containers/AlertTemplate/AlertTemplatesPage"
    }
  }];
  const routes = [{
    ordination: '09.01',
    name: 'Dashboard',
    key: logisticPath + "/dashboard",
    routePath: logisticPath + "/dashboard",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DashboardFreighterPage",
      import_path: "containers/Dashboard"
    }
  }, {
    ordination: '09.02',
    name: 'Contratos',
    key: logisticPath + "/contracts",
    routePath: logisticPath + "/contracts",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "ContractsPage",
      import_path: "containers/contracts/ContractsPage"
    },
    dividerAbove: true
  }, {
    ordination: '09.03',
    name: 'Central de fretes',
    key: logisticPath + "/freight_center",
    routePath: logisticPath + "/freight_center",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "FreightsPage",
      import_path: "containers/freights/FreightsPage"
    }
  }, {
    ordination: '09.04',
    name: 'Fretes disponíveis',
    key: logisticPath + "/available_freight",
    routePath: logisticPath + "/available_freight",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "AvailableFreightsPage",
      import_path: "containers/availableFreights/AvailableFreightsPage"
    }
  }, {
    ordination: '09.05',
    name: 'Meus fretes',
    key: logisticPath + "/my_freights",
    routePath: logisticPath + "/my_freights",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "MyFreightsPage",
      import_path: "containers/myfreight/MyFreightsPage"
    }
  }, {
    ordination: '09.06',
    name: 'Viagens',
    key: logisticPath + "/travels",
    routePath: logisticPath + "/travels",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "TravelsPage",
      import_path: "containers/travel/TravelsPage"
    }
  }, {
    ordination: '09.07',
    name: 'Configurações',
    key: logisticConfigPath,
    routePath: logisticConfigPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    dividerAbove: true,
    children: logisticConfigRoutes
  }];
  return {
    ordination: '09',
    name: 'Logística',
    key: logisticPath,
    kind: 'module',
    status: 'active',
    routePath: logisticPath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'logistic',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: routes
  };
};
export const buildCustomerFeatures = () => {
  const logisticPath = "/" + LOGISTIC;
  const customerLogisticRoutes = [{
    ordination: '09.60',
    name: 'Meus fretes',
    key: logisticPath + "/my_freights",
    routePath: logisticPath + "/my_freights",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "CustomerFreightsPage",
      import_path: "containers/customerFreights/CustomerFreightsPage"
    }
  }, {
    ordination: '09.61',
    name: 'Posição para carga/descarga',
    key: logisticPath + "/freight_event_position",
    routePath: logisticPath + "/freight_event_position",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "FreightPositionPage",
      import_path: "containers/customerFreights/FreightPositionPage"
    }
  }];
  return {
    ordination: '09',
    name: 'Logística',
    key: logisticPath,
    kind: 'module',
    status: 'active',
    routePath: logisticPath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'logistic',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: customerLogisticRoutes
  };
};
export const buildAssociatedFeatures = () => {
  const logisticPath = "/" + LOGISTIC;
  const associatedLogisticRoutes = [{
    ordination: '09.50',
    name: 'Fretes disponíveis',
    key: logisticPath + "/available_freight",
    routePath: logisticPath + "/available_freight",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "AvailableFreightsPage",
      import_path: "containers/availableFreights/AvailableFreightsPage"
    }
  }, {
    ordination: '09.51',
    name: 'Meus fretes',
    key: logisticPath + "/my_freights",
    routePath: logisticPath + "/my_freights",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "MyFreightsPage",
      import_path: "containers/myfreight/MyFreightsPage"
    }
  }];
  return {
    ordination: '09',
    name: 'Logística',
    key: logisticPath,
    kind: 'module',
    status: 'active',
    routePath: logisticPath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'logistic',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: associatedLogisticRoutes
  };
};