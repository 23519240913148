import gql from "graphql-tag";
import { chartAccountSimpleFragment } from "./ChartAccountSimple";
export const accountFragment = gql`
  fragment AccountingAccount on AccountingAccount {
    id,
    name,
    start,
    finish,
    kind,
    classification,
    chartAccount {
      ...ChartAccountSimple
    }
    chartAccounts {
      ...ChartAccountSimple
    }
  }
  ${chartAccountSimpleFragment}
`;