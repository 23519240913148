import { CURRENCY_FORMAT, DONUT_CHART_TYPE, FLOAT_FORMAT, PIE_CHART_TYPE } from "../../constants/Charts";
import { uniq } from "../ListUtil";
import { formatter, valueBuilder } from "./GenericChartUtil";
export const buildPieSeries = (data = [], seriesParams = {}, valueParams = {}, chartType, heading) => {
  return [{
    name: heading,
    type: PIE_CHART_TYPE,
    selectedMode: 'multiple',
    radius: ['30%', '50%'],
    center: chartType === DONUT_CHART_TYPE ? ['50%', '70%'] : ['50%', '50%'],
    startAngle: chartType === DONUT_CHART_TYPE ? 180 : 0,
    roseType: false,
    itemStyle: {
      borderRadius: 8
    },
    data: buildSeriesData(data, seriesParams, valueParams, chartType)
  }];
};
const buildSeriesData = (data, seriesParam, valueParams, chartType) => {
  let total = 0;
  let seriesData = [];
  uniq(data, seriesParam?.field).map(fieldValue => {
    const value = data.filter(item => item[seriesParam?.field] === fieldValue).reduce(function (acc, obj) {
      return acc + obj[valueParams?.field || 'value'];
    }, 0);
    seriesData.push({
      name: valueBuilder(fieldValue, seriesParam?.fieldDictionary),
      value: value,
      label: {
        show: true,
        formatter(param) {
          return formatter(param.value, valueParams?.dataType || CURRENCY_FORMAT, param.name + ': ') + '\n (' + formatter(param.percent * (chartType === DONUT_CHART_TYPE ? 2 : 1), FLOAT_FORMAT) + '%)';
        }
      }
    });
    total += value;
  });
  if (chartType === DONUT_CHART_TYPE) seriesData.push(buildDonutFakeSeries(total));
  return seriesData;
};
const buildDonutFakeSeries = total => {
  return {
    value: total,
    itemStyle: {
      color: 'none',
      decal: {
        symbol: 'none'
      }
    },
    label: {
      show: false
    }
  };
};