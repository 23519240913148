import { CURRENCY_FORMAT } from "../../constants/Charts";
import { THERMOMETER_COLORS } from "../../constants/Colors";
import { formatter } from "./GenericChartUtil";
export const buildThermometerSeries = (data = [], valueParams = {}) => {
  return [{
    type: 'gauge',
    axisLine: {
      lineStyle: {
        width: 20,
        color: [[0.1, THERMOMETER_COLORS[0]], [0.2, THERMOMETER_COLORS[1]], [0.3, THERMOMETER_COLORS[2]], [0.4, THERMOMETER_COLORS[3]], [0.5, THERMOMETER_COLORS[4]], [0.6, THERMOMETER_COLORS[5]], [0.7, THERMOMETER_COLORS[6]], [0.8, THERMOMETER_COLORS[7]], [0.9, THERMOMETER_COLORS[8]], [1, THERMOMETER_COLORS[9]]]
      }
    },
    pointer: {
      itemStyle: {
        color: 'auto'
      }
    },
    axisTick: {
      distance: -10,
      length: 10,
      lineStyle: {
        color: '#fff',
        width: 2
      }
    },
    splitLine: {
      distance: -30,
      length: 30,
      lineStyle: {
        color: '#fff',
        width: 4
      }
    },
    axisLabel: {
      color: 'inherit',
      distance: 40,
      fontSize: 20
    },
    detail: {
      valueAnimation: true,
      width: '60%',
      lineHeight: 40,
      borderRadius: 8,
      offsetCenter: [0, '30%'],
      fontSize: 30,
      fontWeight: 'bolder',
      formatter: value => {
        return formatter(value, valueParams?.dataType || CURRENCY_FORMAT, '', undefined, valueParams?.suffix || '');
      },
      color: 'inherit'
    },
    data: data
  }];
};