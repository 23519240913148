import gql from "graphql-tag";
import { addressFragment, personSimpleFragment, companySimpleFragment } from 'admin-front';
import { vehicleBodyworkFragment, vehicleTypeFragment } from 'fleet-front';
import { contractProductFragment } from "./ContractProduct";
import { contractInsuranceFragment } from "./ContractInsurance";
export const contractSimpleFragment = gql`
  fragment ContractSimple on Contract  { 
    id,
    operationId,    
    tracker,
    priority,
    number,
    periodOfValidity,
    canvas,
    description,
    start,
    finish,
    truckTires,
    truckAxis,
    truckHeight,
    truckWidth,
    truckLength,
    canGenerateFreight,
    freightMethod,
    serviceType,
    modal,
    serviceTakerIdentifier,
    pricingMethod,
    pricingValue,
    advanceMethod,
    advanceUnitaryValue,
    additionalTransportationFeature,
    additionalServiceFeature,
    additionalCargoFeature,
    receiverWithdraw,
    receiverDetail,    
    personOrigin {
      ...PersonSimple
    },
    addressOrigin {
      ...Address
    },
    personDestination {
      ...PersonSimple
    },
    addressDestination {
      ...Address
    },
    company {
      ...CompanySimple
    },
    serviceTaker {
      ...PersonSimple
    }, 
    serviceTakerAddress {
      ...Address
    },
    bodyworks {
      ...VehicleBodywork
    },
    vehicleTypes {
      ...VehicleType
    },
    products {
      ...ContractProduct
    },
    insurances {
      ...ContractInsurance
    }
  }
  ${companySimpleFragment}  
  ${personSimpleFragment}
  ${addressFragment}
  ${vehicleBodyworkFragment}
  ${vehicleTypeFragment}  
  ${contractProductFragment}
  ${contractInsuranceFragment}
`;