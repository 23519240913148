import { BAR_CHART_TYPE, CURRENCY_FORMAT, LINE_CHART_TYPE } from "../../constants/Charts";
import { uniq } from "../ListUtil";
import { buildMarkLine, formatter, valueBuilder } from "./GenericChartUtil";
export const buildLineBarSeries = (data = [], seriesParams = [], valuesParams = [], chartType, xAxisParams, markLineData) => {
  let series = [];
  uniq(data, seriesParams[0]?.field || 'name').map(item => {
    series.push(buildSeries(data, item, seriesParams[0], valuesParams[0], chartType, xAxisParams, markLineData));
    if (valuesParams[1]) series.push(buildSeries(data, item, seriesParams[1], valuesParams[1], chartType === BAR_CHART_TYPE ? LINE_CHART_TYPE : BAR_CHART_TYPE, xAxisParams, [], 1));
  });
  return series;
};
export const buildSeries = (data, item, seriesParams = {}, valueParams = {}, chartType, xAxisParams, markLineData = [], yIndex = 0) => {
  return {
    name: valueBuilder(item, seriesParams?.fieldDictionary, seriesParams?.fieldName),
    type: chartType,
    smooth: true,
    yAxisIndex: yIndex,
    data: buildXData(data, seriesParams?.field || 'name', valueParams?.field || 'value', xAxisParams, item),
    label: {
      formatter: (params, index) => formatter(params.value, valueParams?.dataType || CURRENCY_FORMAT, params.seriesName + ': \n')
    },
    tooltip: {
      valueFormatter: value => formatter(value, valueParams?.dataType || CURRENCY_FORMAT)
    },
    endLabel: {
      show: true,
      align: 'left',
      position: 'top',
      fontWeight: 'bold',
      fontSize: 12,
      overflow: 'breakAll',
      offset: [-100, -10]
    },
    markLine: buildMarkLine(markLineData, valueParams?.dataType || CURRENCY_FORMAT)
  };
};
const buildXData = (data, seriesField, valueField = 'value', xAxisParams, item) => {
  let seriesData = [];
  const records = (data || []).filter(record => record[seriesField] === item);
  (xAxisParams?.data || []).map(data => {
    const value = records.filter(record => record[seriesField] === item && record[xAxisParams?.field || 'date'] === data)[0]?.[valueField] || 0;
    seriesData.push(value);
  });
  return seriesData;
};