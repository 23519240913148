import { round, KG, KM, TON } from "grifo-components";
import { CARGO_VALUE_PERCENTAGE, TRAVEL_VALUE_PERCENTAGE } from "../constants/freights/FreightFieldsOptions";
export const calculateDiscount = (rate, calculationMethod, form) => {
  let basis = 0;
  if (calculationMethod === KM) basis = form.getFieldsValue().mileage;else if (calculationMethod === TON) basis = form.getFieldsValue().measureType === KG ? form.getFieldsValue().cargoGrossWeight / 1000 : form.getFieldsValue().cargoGrossWeight;else if (calculationMethod === TRAVEL_VALUE_PERCENTAGE) basis = form.getFieldsValue().value;else if (calculationMethod === CARGO_VALUE_PERCENTAGE) basis = form.getFieldsValue().cargoValue;else rate = 0;
  return {
    calculationMethod: calculationMethod,
    basis: basis,
    rate: rate,
    value: round(basis * ([TRAVEL_VALUE_PERCENTAGE, CARGO_VALUE_PERCENTAGE].includes(calculationMethod) ? rate / 100 : rate))
  };
};
export const calculateValue = (rate, calculationMethod, form, indexName) => {
  let calculation = calculateDiscount(rate, calculationMethod, form);
  let discounts = form.getFieldsValue().discounts;
  discounts[indexName].calculationMethod = calculationMethod;
  discounts[indexName].basis = calculation.basis;
  discounts[indexName].rate = calculation.rate;
  discounts[indexName].value = calculation.value;
  form.setFieldsValue({
    discounts: discounts
  });
};
export const calculateRate = (value, calculationMethod, form, indexName) => {
  let rate = 0;
  if (calculationMethod === KM) rate = round(value / form.getFieldsValue().mileage);else if (calculationMethod === TON) rate = round(value / (form.getFieldsValue().measureType === KG ? form.getFieldsValue().cargoGrossWeight / 1000 : form.getFieldsValue().cargoGrossWeight));else if (calculationMethod === TRAVEL_VALUE_PERCENTAGE) rate = round(value * 100 / form.getFieldsValue().value);else if (calculationMethod === CARGO_VALUE_PERCENTAGE) rate = round(value * 100 / form.getFieldsValue().cargoValue);else rate = 0;
  let discounts = form.getFieldsValue().discounts;
  discounts[indexName].rate = rate;
  form.setFieldsValue({
    discounts: discounts
  });
};