import gql from "graphql-tag";
import { freighterOperationFragment } from "./FreighterOperation";
export const operationParentFragment = gql`
  fragment FreighterOperationParent on FreighterOperation {
    ...FreighterOperation
    children {
      ...FreighterOperation,
      children {
        ...FreighterOperation,
        children {
          ...FreighterOperation,
          children {
            ...FreighterOperation,
            children {
              ...FreighterOperation,
            }
          }
        }
      }
    }
  }
  ${freighterOperationFragment} 
`;