import gql from 'graphql-tag';
import { accountingCompanyFragment } from '../fragments/AccountingCompany';
export const GQL_ACCOUNTING_COMPANIES_LIST = gql`
  {
    accountingCompanies {
      ...AccountingCompany,
      headOffice {
        ...AccountingCompany,      
      },
      branches {
        ...AccountingCompany,
      }      
    }    
  }
  ${accountingCompanyFragment}
`;
export const GQL_COMPANY_STORE = gql`
  mutation StoreAccountingCompany($attributes: AccountingCompanyAttributes!) {
    storeAccountingCompany(input: { attributes: $attributes }) {
      accountingCompany {
        ...AccountingCompany,
      },
      errors
    }
  }
  ${accountingCompanyFragment}
`;