import gql from "graphql-tag";
import { accountFragment } from "../fragments/Account";
export const GQL_ACCOUNTS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $accountFilter: AccountingAccountFilter) {    
    accountingAccountsPaginated(first: $pageSize, after: $cursor, accountFilter: $accountFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...AccountingAccount
        }
      }
    }    
  }
  ${accountFragment}
`;
export const GQL_ALL_ACCOUNTS_SEARCH = gql`
  query GetAllAccounts($text: String) {
    accountingAccounts(all: $text) {
      ...AccountingAccount
    }
  }
  ${accountFragment}
`;
export const GQL_ACCOUNTS_ON_CHART_SEARCH = gql`
  query GetOnChart($name: String) {
    accountingAccounts(onChart: $name) {
      ...AccountingAccount
    }
  }
  ${accountFragment}
`;
export const GQL_ACCOUNT_STORE = gql`
  mutation StoreAccountingAccount($attributes: AccountingAccountAttributes!) {
    storeAccountingAccount(input: {attributes: $attributes}) { 
      account { 
        ...AccountingAccount,
      }, 
      errors 
    } 
  }
  ${accountFragment}  
`;
export const GQL_SEARCH_ACCOUNT_BY_KIND = gql`
  query Search($filter: FilterAccount) {    
    accountingAccounts(filter: $filter) { 
      ...AccountingAccount
    }
  }
  ${accountFragment}   
`;
export const GQL_SEARCH_ACCOUNT_BY_ID = gql`
  query GetById($id: String) {    
    accountingAccounts(id: $id) { 
      ...AccountingAccount
    }
  }
  ${accountFragment}   
`;