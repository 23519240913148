import { round, KG, KM, TON } from "grifo-components";
import { CARGO_VALUE_PERCENTAGE, TRAVEL_VALUE_PERCENTAGE } from "../constants/freights/FreightFieldsOptions";
export const calculateFreightValue = (unitaryValue, pricingMethod, value, basis = {}, form) => {
  let calculatedValue = value || 0;
  if (pricingMethod === KM) calculatedValue = unitaryValue * form.getFieldsValue().mileage;else if (pricingMethod === TON) calculatedValue = round(unitaryValue * (form.getFieldsValue().measureType === KG ? form.getFieldsValue().cargoGrossWeight / 1000 : form.getFieldsValue().cargoGrossWeight));else if (pricingMethod === CARGO_VALUE_PERCENTAGE) calculatedValue = round(form.getFieldsValue().cargoValue * (unitaryValue / 100));else form.setFieldsValue({
    pricingValue: 0
  });
  form.setFieldsValue({
    value: calculatedValue
  });
  const advanceMethod = form.getFieldsValue().advanceMethod;
  if (advanceMethod === TRAVEL_VALUE_PERCENTAGE) {
    basis.freightValue = calculatedValue;
    calculateAdvanceValue(form.getFieldsValue().advanceUnitaryValue, advanceMethod, form.getFieldsValue().advanceValue, basis, form);
  }
  return calculatedValue;
};
export const calculatePricingValue = (value, pricingMethod, basis = {}, form) => {
  if (pricingMethod === KM) form.setFieldsValue({
    pricingValue: round(value / basis.mileage)
  });else if (pricingMethod === TON) form.setFieldsValue({
    pricingValue: round(value / (form.getFieldsValue().measureType === KG ? basis.cargoGrossWeight / 1000 : basis.cargoGrossWeight))
  });else if (pricingMethod === CARGO_VALUE_PERCENTAGE) form.setFieldsValue({
    pricingValue: round(value * 100 / basis.cargoValue)
  });else form.setFieldsValue({
    pricingValue: 0
  });
  let advanceMethod = form.getFieldsValue().advanceMethod;
  if (advanceMethod === TRAVEL_VALUE_PERCENTAGE) calculateAdvanceValue(form.getFieldsValue().advanceUnitaryValue, advanceMethod, form.getFieldsValue().advanceValue, basis, form);
};
export const calculateAdvanceValue = (advanceUnitaryValue = 0, advanceMethod, value = 0, basis = {}, form) => {
  let calculatedValue = value || 0;
  if (advanceMethod === KM) calculatedValue = advanceUnitaryValue * basis.mileage;else if (advanceMethod === TON) calculatedValue = round(advanceUnitaryValue * (form.getFieldsValue().measureType === KG ? basis.cargoGrossWeight / 1000 : basis.cargoGrossWeight));else if (advanceMethod === TRAVEL_VALUE_PERCENTAGE) calculatedValue = round(basis.freightValue * (advanceUnitaryValue / 100));else if (advanceMethod === CARGO_VALUE_PERCENTAGE) calculatedValue = round(basis.cargoValue * (advanceUnitaryValue / 100));else form.setFieldsValue({
    advanceUnitaryValue: 0
  });
  form.setFieldsValue({
    advanceValue: calculatedValue
  });
};
export const calculateAdvanceUnitaryValue = (value, advanceMethod, basis = {}, form) => {
  if (advanceMethod === KM) form.setFieldsValue({
    advanceUnitaryValue: round(value / basis.mileage)
  });else if (advanceMethod === TON) form.setFieldsValue({
    advanceUnitaryValue: round(value / (form.getFieldsValue().measureType === KG ? basis.cargoGrossWeight / 1000 : basis.cargoGrossWeight))
  });else if (advanceMethod === TRAVEL_VALUE_PERCENTAGE) form.setFieldsValue({
    advanceUnitaryValue: round(value * 100 / basis.freightValue)
  });else if (advanceMethod === CARGO_VALUE_PERCENTAGE) form.setFieldsValue({
    advanceUnitaryValue: round(value * 100 / basis.cargoValue)
  });else form.setFieldsValue({
    advanceUnitaryValue: 0
  });
};
export const convertGrossWeight = (measureType, cargoGrossWeight) => {
  return round(measureType === KG ? cargoGrossWeight * 1000 : cargoGrossWeight / 1000);
};
export const sumProductsWeight = (measureType, form) => {
  return (form.getFieldsValue().products || []).reduce(function (previous, current) {
    return previous + (measureType === KG ? (current.weight || 0) * 1000 : current.weight || 0);
  }, 0);
};