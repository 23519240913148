import gql from "graphql-tag";
import { referentialAccountFragment } from "../fragments/ReferentialAccount";
import { referentialAccountSimpleFragment } from "../fragments/ReferentialAccountSimple";
export const GQL_REFERENTIAL_ACCOUNTS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String) {    
    referentialAccountsPaginated(first: $pageSize, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...ReferentialAccountSimple
        }
      }
    }    
  }
  ${referentialAccountSimpleFragment}
`;
export const GQL_ALL_REFERENTIAL_ACCOUNTS_SEARCH = gql`
  query GetAllReferentialAccounts($text: String) {
    referentialAccounts(all: $text) {
      ...ReferentialAccount
    }
  }
  ${referentialAccountFragment}
`;
export const GQL_SEARCH_REFERENTIAL_ACCOUNT_BY_ID = gql`
  query GetById($id: String) {    
    referentialAccounts(id: $id) { 
      ...ReferentialAccount
    }
  }
  ${referentialAccountFragment}   
`;
export const GQL_SEARCH_REFERENTIAL_ACCOUNT_BY_KIND = gql`
  query GetByKind($kind: String) {    
    referentialAccounts(kind: $kind) { 
      ...ReferentialAccount
    }
  }
  ${referentialAccountFragment}   
`;
export const GQL_SEARCH_REFERENTIAL_ACCOUNT_BY_KIND_AND_TAX_REGIME = gql`
  query Search($filter: FilterReferentialAccount) {    
    referentialAccounts(filter: $filter) { 
      ...ReferentialAccount
    }
  }
  ${referentialAccountFragment}   
`;
export const GQL_REFERENTIAL_ACCOUNT_STORE = gql`
  mutation StoreReferentialAccount($attributes: ReferentialAccountAttributes!) {
    storeReferentialAccount(input: {attributes: $attributes}) { 
      referentialAccount { 
        ...ReferentialAccount,
      }, 
      errors 
    } 
  }
  ${referentialAccountFragment}  
`;