import gql from "graphql-tag";
import { commercialUnitFragment, packingKindFragment, productFragment } from 'stock-front';
export const freightProductFragment = gql`
  fragment FreightProduct on FreightProduct  { 
    id,
    quantity,
    quantityLoaded,
    quantityUnloaded,
    width,
    length,
    weight,    
    height,
    prevalent,
    measureType,
    unitForTransport,
    escort,
    dangerousCargo,
    sensitiveCargo,
    valuableCargo,
    contractProductId,
    productId,
    product {
      ...Product
    }
    commercialUnitId,
    commercialUnit {
      ...CommercialUnit
    }
    packingKindId,
    packingKind {
      ...PackingKind
    }   
  }
  ${productFragment}
  ${commercialUnitFragment}  
  ${packingKindFragment}    
`;