export const DAILY = 'daily';
export const MONTHLY = 'monthly';
export const TO_PAY = 'to_pay';
export const TO_RECEIVE = 'to_receive';
export const TAXES_TO_RECOVERY = 'taxes_to_recovery';
export const TAXES_TO_COLLECT = 'taxes_to_collect';
export const TRANSFERS = 'transfers';
export const ADDITION = 'addition';
export const DEDUCTION = 'deduction';
export const WRITE_OFF = 'write_off';
export const TO_PAY_IN_ADVANCE = 'to_pay_in_advance';
export const TO_RECEIVE_IN_ADVANCE = 'to_receive_in_advance';
export const CAPITAL_PAYMENT = 'capital_payment';
export const OF_CURRENT_ASSET = 'of_current_asset';
export const CURRENT_ASSET_ADDITION = 'current_asset_addition';
export const CURRENT_ASSET_DEDUCTION = 'current_asset_deduction';
export const PROTEST_RUNNING_DAYS = 'protest_running_days';
export const DO_NOT_PROTEST = 'do_not_protest';
export const CANCEL_PROTEST_INSTRUCTION = 'cancel_protest_instruction';
export const FIXED_COST = 'fixed_cost';
export const VARIABLE_COST = 'variable_cost';
export const FIXED_EXPENSE = 'fixed_expense';
export const ESSENTIAL_VARIABLE_EXPENSE = 'essential_variable_expense';
export const EXPENDABLE_VARIABLE_EXPENSE = 'expendable_variable_expense';
export const OPERATING_INCOME = 'operating_income';
export const NON_OPERATING_INCOME = 'non_operating_income';
export const FINANCIAL_INCOME = 'financial_income';
export const OCCASIONAL_INCOME = 'occasional_income';
export const SUBSIDIES_INCOME = 'subsidies_income';
export const OPERATIONS_INTEREST_FREQUENCY = [{
  value: DAILY,
  label: 'Dia'
}, {
  value: MONTHLY,
  label: 'Mês'
}];
export const OPERATIONS_KIND = [{
  value: TO_PAY,
  label: 'À Pagar'
}, {
  value: TO_RECEIVE,
  label: 'À Receber'
}, {
  value: TAXES_TO_RECOVERY,
  label: 'Tributo à recuperar'
}, {
  value: TAXES_TO_COLLECT,
  label: 'Tributo à recolher'
}, {
  value: TRANSFERS,
  label: 'Transferência'
}, {
  value: ADDITION,
  label: 'Acréscimo'
}, {
  value: DEDUCTION,
  label: 'Desconto'
}, {
  value: WRITE_OFF,
  label: 'Baixa'
}, {
  value: TO_PAY_IN_ADVANCE,
  label: 'Adiantamento à pagar'
}, {
  value: TO_RECEIVE_IN_ADVANCE,
  label: 'Adiantamento à receber'
}, {
  value: CAPITAL_PAYMENT,
  label: 'Capital a integralizar'
}, {
  value: OF_CURRENT_ASSET,
  label: 'Para disponibilidades'
}];
export const OPERATIONS_CLASSIFICATION = [{
  value: FIXED_COST,
  label: 'Custo fixo'
}, {
  value: VARIABLE_COST,
  label: 'Custo variável'
}, {
  value: FIXED_EXPENSE,
  label: 'Despesa fixa'
}, {
  value: ESSENTIAL_VARIABLE_EXPENSE,
  label: 'Despesa variável indispensável'
}, {
  value: EXPENDABLE_VARIABLE_EXPENSE,
  label: 'Despesa variável dispensável'
}, {
  value: OPERATING_INCOME,
  label: 'Receita Operacional'
}, {
  value: NON_OPERATING_INCOME,
  label: 'Receita Não Operacional'
}, {
  value: FINANCIAL_INCOME,
  label: 'Receita Financeira'
}, {
  value: OCCASIONAL_INCOME,
  label: 'Receita Eventual'
}, {
  value: SUBSIDIES_INCOME,
  label: 'Receita de Subvenção/Subsídio'
}];
export const OPERATIONS_CLASSIFICATION_BY_KEY = [{
  value: '0',
  label: 'Custo fixo'
}, {
  value: '1',
  label: 'Custo variável'
}, {
  value: '2',
  label: 'Despesa fixa'
}, {
  value: '3',
  label: 'Despesa variável indispensável'
}, {
  value: '4',
  label: 'Despesa variável dispensável'
}, {
  value: '10',
  label: 'Receita Operacional'
}, {
  value: '11',
  label: 'Receita Não Operacional'
}, {
  value: '12',
  label: 'Receita Financeira'
}, {
  value: '13',
  label: 'Receita Eventual'
}, {
  value: '14',
  label: 'Receita de Subvenção/Subsídio'
}];
export const TO_PAY_OPERATIONS_CLASSIFICATION = [{
  value: FIXED_COST,
  label: 'Custo fixo'
}, {
  value: VARIABLE_COST,
  label: 'Custo variável'
}, {
  value: FIXED_EXPENSE,
  label: 'Despesa fixa'
}, {
  value: ESSENTIAL_VARIABLE_EXPENSE,
  label: 'Despesa variável indispensável'
}, {
  value: EXPENDABLE_VARIABLE_EXPENSE,
  label: 'Despesa variável dispensável'
}];
export const TO_RECEIVE_OPERATIONS_CLASSIFICATION = [{
  value: OPERATING_INCOME,
  label: 'Receita Operacional'
}, {
  value: NON_OPERATING_INCOME,
  label: 'Receita Não Operacional'
}, {
  value: FINANCIAL_INCOME,
  label: 'Receita Financeira'
}, {
  value: OCCASIONAL_INCOME,
  label: 'Receita Eventual'
}, {
  value: SUBSIDIES_INCOME,
  label: 'Receita de Subvenção/Subsídio'
}];
export const PROTEST_CODE_OPTIONS = [{
  value: PROTEST_RUNNING_DAYS,
  label: 'Protestar dias corridos'
}, {
  value: DO_NOT_PROTEST,
  label: 'Não Protestar'
}, {
  value: CANCEL_PROTEST_INSTRUCTION,
  label: 'Cancelar Instrução de Protesto',
  disabled: true
}];
export const OPERATIONS_LIST_KIND = [{
  value: TO_PAY,
  label: 'À Pagar'
}, {
  value: TO_RECEIVE,
  label: 'À Receber'
}, {
  value: TAXES_TO_RECOVERY,
  label: 'Tributo à recuperar'
}, {
  value: TAXES_TO_COLLECT,
  label: 'Tributo à recolher'
}, {
  value: TRANSFERS,
  label: 'Transferência'
}, {
  value: ADDITION,
  label: 'Acréscimo'
}, {
  value: DEDUCTION,
  label: 'Desconto'
}, {
  value: WRITE_OFF,
  label: 'Baixa'
}, {
  value: TO_PAY_IN_ADVANCE,
  label: 'Adiantamento à pagar'
}, {
  value: TO_RECEIVE_IN_ADVANCE,
  label: 'Adiantamento à receber'
}, {
  value: CAPITAL_PAYMENT,
  label: 'Capital a integralizar'
}, {
  value: OF_CURRENT_ASSET,
  label: 'Para disponibilidades'
}, {
  value: CURRENT_ASSET_ADDITION,
  label: 'Entrada para disponibilidades'
}, {
  value: CURRENT_ASSET_DEDUCTION,
  label: 'Saída para disponibilidades'
}];