import gql from "graphql-tag";
import { chartSimpleFragment } from "./ChartSimple";
export const chartAccountSimpleFragment = gql`
  fragment ChartAccountSimple on ChartAccount {
    id,
    ancestry,
    parentId,
    classification,
    key,
    normalBalance,
    accountingMethod,
    accountingAccountId,
    personId,
    level,
    chart {
      ...ChartSimple
    }
  }
  ${chartSimpleFragment}
`;