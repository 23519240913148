import gql from "graphql-tag";
import { dailyValueFragment, dailyBalanceFragment } from "grifo-components";
export const cashFlowFragment = gql`
  fragment CashFlow on CashFlow {
    id,
    debits {
      ...DailyValue
    },
    credits {
      ...DailyValue
    },
    balances {
      ...DailyBalance
    },
    predictedDebits {
      ...DailyValue
    },
    predictedCredits {
      ...DailyValue
    },
    predictedBalances {
      ...DailyBalance
    }
  }
  ${dailyValueFragment}
  ${dailyBalanceFragment}
`;