import { CANDLESTICK_CHART_TYPE, CURRENCY_FORMAT, LINE_CHART_TYPE } from "../../constants/Charts";
import { NEGATIVE_RED, POSITIVE_GREEN } from "../../constants/Colors";
import { uniq } from "../ListUtil";
import { buildMarkLine, formatter, valueBuilder } from "./GenericChartUtil";
import { buildSeries } from "./SeriesLineBarBuilderUtil";
export const buildCandlestickSeries = (data, seriesParams = [], valuesParams = [], xAxisParams = {}, markLineData) => {
  let series = [];
  uniq(data, seriesParams[0].field || 'name').map(item => {
    series.push({
      name: valueBuilder(item, seriesParams[0].fieldDictionary, seriesParams[0].fieldName),
      type: CANDLESTICK_CHART_TYPE,
      smooth: true,
      data: buildXData(data, seriesParams[0].field, valuesParams[0]?.field, xAxisParams, item),
      label: {
        formatter: (params, index) => formatter(params.value, valuesParams[0]?.dataType || CURRENCY_FORMAT, params.seriesName + ': \n')
      },
      itemStyle: {
        color: POSITIVE_GREEN,
        color0: NEGATIVE_RED,
        borderColor: POSITIVE_GREEN,
        borderColor0: NEGATIVE_RED
      },
      endLabel: {
        show: true,
        align: 'left',
        position: 'top',
        fontWeight: 'bold',
        fontSize: 12,
        overflow: 'breakAll',
        offset: [-100, -10]
      },
      markLine: buildMarkLine(markLineData, valuesParams[0]?.dataType || CURRENCY_FORMAT)
    });
    if (valuesParams[1]) series.push(buildSeries(data, item, seriesParams[1], valuesParams[1], LINE_CHART_TYPE, xAxisParams, [], 1));
  });
  return series;
};
const buildXData = (data, seriesField = 'name', valueField = 'value', xAxisParams, item) => {
  let seriesData = [];
  const records = data.filter(record => record[seriesField] === item);
  (xAxisParams?.data || []).map(data => {
    const record = records.filter(record => record[seriesField] === item && record[xAxisParams?.field || 'date'] === data)[0];
    const openValue = record?.[valueField[0]] || 0;
    const closeValue = record?.[valueField[1]] || 0;
    const lowValue = record?.[valueField[2]] || 0;
    const highValue = record?.[valueField[3]] || 0;
    seriesData.push([openValue, closeValue, lowValue, highValue]);
  });
  return seriesData;
};