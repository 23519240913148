import gql from "graphql-tag";
export const freightOfferFragment = gql`
  fragment FreightOffer on FreightOffer { 
    id,
    freightId,
    pricingMethod, 
    pricingValue,
    value,
    advanceMethod,
    advanceValue,
    advanceUnitaryValue,
    mileage,
    cargoGrossWeight,
    cargoValue,
    date,
  }
`;