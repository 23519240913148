import gql from "graphql-tag";
import { personSimpleFragment } from 'admin-front';
export const freightInsuranceFragment = gql`
  fragment FreightInsurance on FreightInsurance { 
    id,
    value,
    policy,
    liability,
    registration,
    insuranceCompanyId,
    insuranceCompany {
      ...PersonSimple
    },
    naturalPersonId,
    naturalPerson {
      ...PersonSimple
    },
    juridicalPersonId,
    juridicalPerson {
      ...PersonSimple
    }
  }
  ${personSimpleFragment}
`;