export { default as AvailableFreightPage } from './availableFreights/AvailableFreightPage';
export { default as AvailableFreightsPage } from './availableFreights/AvailableFreightsPage';
export { default as NegotiationPage } from './availableFreights/NegotiationPage';
export { default as ViewBidPage } from './availableFreights/ViewBidPage';
export { default as FreightPage } from './freights/FreightPage';
export { default as FreightsPage } from './freights/FreightsPage';
export { default as ViewFreightPage } from './freights/ViewFreightPage';
export { default as LogbookPage } from './myfreight/LogbookPage';
export { default as MyFreightPage } from './myfreight/MyFreightPage';
export { default as MyFreightsPage } from './myfreight/MyFreightsPage';
export { default as SkillPage } from './skills/SkillPage';
export { default as SkillsPage } from './skills/SkillsPage';
export { default as DashboardFreighterPage } from './Dashboard';