export const DEBIT = 'debit';
export const CREDIT = 'credit';
export const REVERSING_ENTRY = 'reversing_entry';
export const REVERSED = 'reversed';
export const MOVEMENTS_KIND = [{
  value: DEBIT,
  label: 'Débito'
}, {
  value: CREDIT,
  label: 'Crédito'
}, {
  value: REVERSING_ENTRY,
  label: 'Estorno'
}, {
  value: REVERSED,
  label: 'Estornado'
}];