import _ from 'lodash';
export const union = (firstArray, secondArray) => {
  return _.union(firstArray, secondArray);
};
export const deepGet = (object, path) => {
  return _.get(object, path);
};
export const deepSet = (object, path, value) => {
  _.set(object, path, value);
};
export const pluck = (array, key) => {
  if (!array) return;
  return array.map(o => o && o[key]);
};
export const uniq = (array, key) => {
  return _.uniq(map(array, key));
};
export const uniqForPlainArray = array => {
  return _.uniq(array);
};
export const cloneArray = array => {
  if (!array) return;
  return _.cloneDeep(array);
};
export const first = array => {
  if (!array) return;
  return array[0];
};
export const last = array => {
  if (!array) return;
  return array[array.length - 1];
};
export const removeLast = array => {
  if (!array) return;
  let resultArray = array.slice();
  resultArray.splice(-1, 1);
  return resultArray;
};
export const removeFirst = array => {
  if (!array) return;
  let resultArray = array.slice();
  resultArray.shift();
  return resultArray;
};
export const packObjectList = (list, uniqueField) => {
  var packedList = [];
  for (var key in list) {
    const selectedId = list[key].id || list[key];
    const found = packedList.find(element => element[uniqueField] === selectedId);
    if (!found) packedList.push({
      [uniqueField]: selectedId
    });
  }
  return packedList;
};
export const mountTreePath = (list, id, fieldId = "id", fieldname = "name", fieldChildren = "children") => {
  let path = [];
  for (var index in list) {
    if (list[index][fieldId] === id) path.push(list[index][fieldname]);else if (list[index][fieldChildren]) {
      let child = mountTreePath(list[index][fieldChildren], id, fieldId, fieldname, fieldChildren);
      if (child.length > 0) {
        path.push(list[index][fieldname]);
        path = union(path, child);
      }
    }
  }
  return path;
};
export const mountTree = (list, fieldId = "id", fieldname = "name", fieldChildren = "children") => {
  if (!list || list.length === 0) return undefined;
  let tree = [];
  for (var index in list) {
    let item = list[index];
    tree.push({
      value: item[fieldId],
      label: item[fieldname],
      disabled: item.discardedAt || false,
      generatesMovement: item.generatesMovement || false,
      children: mountTree(item[fieldChildren], fieldId, fieldname, fieldChildren)
    });
  }
  return tree;
};
export const filterTreeById = (list, purgedId) => {
  let filtered = [];
  for (var index in list) {
    let item = list[index];
    item.children = filterTreeById(item.children, purgedId);
    if (item.id !== purgedId) filtered.push(item);
  }
  return filtered;
};
export const findChild = (list, key, fieldKey = 'key') => {
  if (!(list || []).length) return undefined;
  if (!key) return undefined;
  for (let item of list) {
    if (item[fieldKey] === key) return item;
    if (item.children) {
      const found = findChild(item.children, key, fieldKey);
      if (found) return found;
    }
  }
  return undefined;
};
export const updateChild = (list, object) => {
  if (!object) return [];
  if (!(list || []).length) return [];
  for (let item of list) {
    if (item.key === object.key) {
      const index = list.findIndex(item => item.key === object.key);
      list[index] = object;
      return list;
    }
    if (item.children) item.children = updateChild(item.children, object);
  }
  return list;
};
export const getLabelByValue = (options, value, attribute = "label") => {
  return ((options || []).find(e => e?.value === value) || {})[attribute];
};
export const getOptionsByValue = (options, value, attribute = "options") => {
  return (options.find(e => e.value === value) || {})[attribute];
};
export const getNextSituationByValue = (options, value, attribute = "nextSituation") => {
  return (options.find(e => e.value === value) || {})[attribute];
};
export const sortByReference = (list, referenceList) => {
  return _.map(list, i => {
    return referenceList.shift();
  });
};
export const sortByKey = (list, key) => {
  return _.sortBy(list, key);
};
export const sortByTwoKeys = (firstKey, secondKey) => {
  return function (a, b) {
    let firstOrdination = order(a, b, firstKey);
    return firstOrdination !== 0 ? firstOrdination : order(a, b, secondKey);
  };
};
export const sort = key => {
  return function (a, b) {
    return order(a, b, key);
  };
};
export const order = (elementA, elementB, key) => {
  if (elementA[key] > elementB[key]) return 1;else if (elementA[key] < elementB[key]) return -1;else return 0;
};
export const map = (list, key) => {
  return Array.prototype.map.call(list, function (object) {
    return object[key];
  });
};
export const uniquenessForObject = (list, object, key = "id") => {
  return [...new Map(list.map(item => [item[object][key], item[object]])).values()];
};
export const allKeysEmpty = list => {
  let allPropsEmpty = true;
  for (var key in list) {
    allPropsEmpty = list[key] === undefined;
    if (!allPropsEmpty) break;
  }
  return allPropsEmpty;
};
export const getDescriptionByValue = (options, value, attribute = "description") => {
  return (options.find(e => e.value === value) || {})[attribute];
};
export const sum = (list, attribute = "value") => {
  let sumarize = list.reduce(function (previous, current) {
    return previous + (current[attribute] || 0);
  }, 0);
  return sumarize;
};
export const sumAttributes = list => {
  return Object.values(list).reduce((previous, current) => (previous || 0) + (current || 0), 0);
};