import { CURRENCY_FORMAT, GRAPH_CHART_TYPE, HEATMAP_CHART_TYPE } from "../../constants/Charts";
import { CALENDAR_EMPHASIS, CALENDAR_HIGH, CALENDAR_LINK } from "../../constants/Colors";
import { endOfMonth, firstDateComparison, lastDateComparison, simpleDateFormat, startOfMonth } from "../DateUtil";
import { formatter } from "./GenericChartUtil";
export const buildCalendarSeries = (calendarData = [], graphData = [], calendarDateField = 'date', valueParams = {}, seriesParams = {}) => {
  return [{
    type: GRAPH_CHART_TYPE,
    name: seriesParams[0].fieldName,
    edgeSymbol: ['none', 'arrow'],
    coordinateSystem: 'calendar',
    links: buildGraphLinks(graphData),
    symbolSize: 18,
    calendarIndex: 0,
    itemStyle: {
      color: CALENDAR_EMPHASIS,
      shadowBlur: 9,
      shadowOffsetX: 1.5,
      shadowOffsetY: 3,
      shadowColor: '#555'
    },
    lineStyle: {
      color: CALENDAR_LINK,
      width: 1,
      opacity: 0.5
    },
    data: graphData.map(data => [data[calendarDateField], data[valueParams?.field || 'value']]),
    z: 20
  }, {
    type: HEATMAP_CHART_TYPE,
    name: seriesParams[1].fieldName,
    coordinateSystem: 'calendar',
    data: calendarData.map(data => [data[calendarDateField], data[valueParams?.field || 'value']]),
    color: CALENDAR_HIGH
  }];
};
const buildGraphLinks = graphData => {
  const links = graphData.map(function (item, idx) {
    return {
      source: idx,
      target: idx + 1
    };
  });
  links.pop();
  return links;
};
export const buildCalendarOptions = (calendarData = [], graphData = [], calendarDateField = 'date') => {
  const startDate = firstDateComparison(graphData[0]?.[calendarDateField], calendarData[0]?.[calendarDateField]);
  const endDate = lastDateComparison(graphData[graphData.length - 1]?.[calendarDateField], calendarData[calendarData.length - 1]?.[calendarDateField]);
  return {
    calendar: {
      top: 'middle',
      left: 'center',
      orient: 'horizontal',
      cellSize: 30,
      yearLabel: {
        margin: 50,
        fontSize: 30
      },
      dayLabel: {
        firstDay: 0
      },
      monthLabel: {
        margin: 15,
        fontSize: 20,
        color: '#999'
      },
      range: [startOfMonth(startDate).format('YYYY-MM-DD'), endOfMonth(endDate).format('YYYY-MM-DD')]
    }
  };
};
export const buildCalendarTooltip = (valueParams = {}) => {
  return {
    tooltip: {
      trigger: 'item',
      formatter: params => params.value ? (Array.isArray(params.value) ? simpleDateFormat(params.value[0]) : undefined) + '<br />' + (params.seriesName + ': ') + formatter(Array.isArray(params.value) ? params.value[1] : params.value, valueParams?.dataType || CURRENCY_FORMAT) : ''
    }
  };
};