import { CURRENCY_FORMAT, DATE_FORMAT, FLOAT_FORMAT, STRING_FORMAT } from "../../constants/Charts";
import { simpleDateFormat } from "../DateUtil";
import { formatFloat } from "../FloatUtil";
import { format as formatCurrency } from '../CurrencyUtil';
import { getLabelByValue } from "../ListUtil";
import { CALENDAR_HIGH, CALENDAR_LOW, RED_BAR } from "../../constants/Colors";
export const buildVisualMapOptions = (data = [], valueParams = {}, config = {
  colorLow: CALENDAR_LOW,
  colorHigh: CALENDAR_HIGH,
  seriesIndex: undefined
}) => {
  const max = data.length > 0 ? Math.max(...data.map(item => item[valueParams?.field || 'value'])) : 0;
  let opts = {
    visualMap: {
      min: 0,
      max: max,
      type: 'piecewise',
      left: 'center',
      top: 70,
      inRange: {
        color: [config.colorLow || CALENDAR_LOW, config.colorHigh || CALENDAR_HIGH]
      },
      orient: 'horizontal',
      showLabel: true,
      formatter: function (value, value2) {
        return formatter(value, valueParams?.dataType || CURRENCY_FORMAT) + ' - ' + formatter(value2, valueParams?.dataType || CURRENCY_FORMAT);
      }
    }
  };
  return config.seriesIndex ? {
    visualMap: {
      seriesIndex: config.seriesIndex,
      ...opts.visualMap
    }
  } : opts;
};
export const buildMarkLine = (markLineData = [], valueParams = {}, color = RED_BAR) => {
  let options = undefined;
  if (markLineData?.length > 0) options = {
    data: markLineData,
    label: {
      position: 'insideStartTop',
      formatter: (params, index) => '{a|' + formatter(params.value, valueParams?.dataType || CURRENCY_FORMAT, params.name + ': ') + '}',
      rich: {
        a: {
          color: color,
          lineHeight: 10
        }
      }
    },
    lineStyle: {
      color: RED_BAR
    }
  };
  return options;
};
export const formatter = (value, dataType = FLOAT_FORMAT, prefix = '', dictionary = undefined, suffix = '') => {
  return (prefix !== '' ? prefix + ' ' : '') + (typeof value === STRING_FORMAT && dataType !== DATE_FORMAT ? valueBuilder(value, dictionary) : dataType === FLOAT_FORMAT ? formatFloat(value, 4) : dataType === CURRENCY_FORMAT ? formatCurrency(value) : dataType === DATE_FORMAT ? simpleDateFormat(value) : value) + (suffix !== '' ? ' ' + suffix : '');
};
export const valueBuilder = (value, fieldDictionary, fieldName, field) => {
  return fieldDictionary ? getLabelByValue(fieldDictionary, Array.isArray(value) ? value[field] : value) : fieldName ? fieldName : Array.isArray(value) ? value[field] : value;
};