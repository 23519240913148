import gql from "graphql-tag";
import { FreightEventFragment } from "../fragments/FreightEvent";
export const GQL_FREIGHT_EVENT_STORE = gql`
  mutation StoreFreightEvent($attributes: FreightEventAttributes!) {
    storeFreightEvent(input: {attributes: $attributes}) { 
      freightEvent {
        ...FreightEvent,
      }, 
      errors 
    } 
  }
  ${FreightEventFragment}
`;