import { CURRENCY_FORMAT, LINE_CHART_TYPE, MAX_HIGHLIGHT_RULE, SOLID_LINE_STYLE_TYPE, TOP_POSITION } from "../../constants/Charts";
import { formatter } from "./GenericChartUtil";
export const buildAreaSeries = (data = [], seriesParams = [], valuesParams = [], xAxisParams = {}) => {
  let series = [];
  seriesParams.map((seriesParam, index) => {
    const seriesData = data[seriesParam.field];
    series.push(buildSerie(seriesData, seriesParam, valuesParams[index], xAxisParams));
  });
  return series;
};
const buildSerie = (item, seriesParams = {}, valueParams = {}, xAxisParams = {}) => {
  return {
    name: seriesParams?.fieldName || item,
    type: seriesParams?.type || LINE_CHART_TYPE,
    smooth: true,
    stack: seriesParams?.stack ? valueParams?.field || 'value' : false,
    itemStyle: {
      color: seriesParams?.color
    },
    lineStyle: {
      type: seriesParams?.lineStyleType || SOLID_LINE_STYLE_TYPE
    },
    areaStyle: {
      opacity: seriesParams?.areaStyleOpactity || 0.1,
      color: seriesParams?.color
    },
    markPoint: {
      symbol: 'pin',
      symbolSize: 0,
      label: {
        position: seriesParams?.labelPosition || TOP_POSITION,
        fontWeight: 'bold',
        fontSize: 14,
        color: seriesParams?.color,
        formatter: (params, index) => formatter(params.value, valueParams?.dataType || CURRENCY_FORMAT)
      },
      data: [{
        type: seriesParams?.highlightRule || MAX_HIGHLIGHT_RULE,
        name: 'Highlight'
      }]
    },
    emphasis: {
      focus: 'series'
    },
    data: buildXData(item, valueParams?.field, xAxisParams, seriesParams?.fillData),
    label: {
      formatter: (params, index) => formatter(params.value, valueParams?.dataType || CURRENCY_FORMAT)
    }
  };
};
const buildXData = (records, valueField = 'value', xAxisParams, fillData = false) => {
  let seriesData = [];
  (xAxisParams?.data || []).map(data => {
    const item = records.filter(record => record[xAxisParams?.field || 'date'] === data)[0];
    if (item || fillData) seriesData.push(item?.[valueField] || undefined);
  });
  return seriesData;
};