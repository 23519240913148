import gql from "graphql-tag";
import { operationFragment } from "financial-front";
export const freighterOperationDiscountFragment = gql`
  fragment FreighterOperationDiscount on FreighterOperationDiscount  { 
    id,
    calculationMethod,
    value,
    financialOperationId,
    financialOperation {
      ...Operation
    }
  } 
  ${operationFragment}  

`;