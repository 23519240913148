import gql from 'graphql-tag';
import { freightsFromPastFourWeeksFragment } from './FreightFromPastFourWeeks';
import { mainStatsFragment } from './MainStats';
export const monthlyStatsFragment = gql`
  fragment MonthlyStats on MonthlyStats {
    km,
    ton,
    freights,
    idleVehicles,
    vehiclesLoading,
    vehiclesTraveling,
    vehiclesUnloading,
    activeVehicles,
    evaluationRate,
    mainProducts {
      ...MainStats
    }
    mainServiceTakers {
      ...MainStats
    }
    freightsFromPastFourWeeks {
      ...FreightsFromPastFourWeeks
    }
  }
  ${mainStatsFragment}
  ${freightsFromPastFourWeeksFragment}
`;