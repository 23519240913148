import gql from 'graphql-tag';
import { evaluationFragment } from '../fragments/Evaluation';
export const GQL_EVALUATION_STORE = gql`
  mutation StoreEvaluation($attributes: [EvaluationAttributes!]) {
    storeEvaluation(input: { attributes: $attributes }) {
      evaluation {
        ...Evaluation
      }
      errors
    }
  }
  ${evaluationFragment}
`;