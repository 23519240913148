export const travelEventParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      seriousness: values.seriousness || 1,
      description: values.description
    }
  };
};
export const travelEventDefaultBuilder = record => {
  let data = record || {};
  return {
    name: data.name,
    seriousness: data.seriousness,
    description: data.description
  };
};