import { CURRENCY_FORMAT, FUNNEL_CHART_TYPE } from "../../constants/Charts";
import { formatter, valueBuilder } from "./GenericChartUtil";
export const buildFunnelSeries = (data = [], valueParams = {}, seriesParams = {}) => {
  let funnelData = [];
  data.map(item => {
    funnelData.push({
      name: valueBuilder(item, seriesParams?.fieldDictionary, undefined, seriesParams.field),
      value: item[valueParams?.field || 'value'],
      label: {
        formatter: (params, index) => formatter(params.value, valueParams?.dataType || CURRENCY_FORMAT)
      }
    });
  });
  return [{
    type: FUNNEL_CHART_TYPE,
    top: 100,
    width: '80%',
    min: 0,
    max: Math.max(...data.map(item => item[valueParams?.field || 'value'])),
    minSize: '0%',
    maxSize: '100%',
    sort: 'descending',
    gap: 2,
    label: {
      show: true,
      position: 'inside'
    },
    labelLine: {
      length: 10,
      lineStyle: {
        width: 1,
        type: 'solid'
      }
    },
    itemStyle: {
      borderColor: '#fff',
      borderWidth: 1
    },
    emphasis: {
      label: {
        fontSize: 20
      }
    },
    data: funnelData
  }];
};