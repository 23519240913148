import gql from "graphql-tag";
import { addressFragment, personSimpleFragment, companySimpleFragment } from 'admin-front';
import { vehicleBodyworkFragment, vehicleTypeFragment, vehicleSimpleFragment } from 'fleet-front';
import { freightProductFragment } from "./FreightProduct";
import { freightInsuranceFragment } from "./FreightInsurance";
import { driverFragment } from "./Driver";
import { freighterOperationFragment } from "./FreighterOperation";
export const FreightSimpleFragment = gql`
  fragment FreightSimple on Freight  { 
    id,
    transactionId,
    situation,
    tracker,
    priority,
    freightMethod,
    serviceType,
    serviceTakerIdentifier,
    loadingTax,
    unloadingTax,
    toll,
    tollPayingResponsible,
    tollValue,
    tollMethod,
    tollVoucherSupplierId,
    tollPurchaseNumber,
    pricingMethod,
    pricingValue,
    value,
    advanceMethod,
    advanceUnitaryValue,
    advanceValue,
    kind,
    canvas,
    measureType,
    cargoGrossWeight,
    cargoValue,
    description,
    additionalTransportationFeature,
    additionalServiceFeature,
    additionalCargoFeature,
    receiverWithdraw,
    receiverDetail,
    collectionDateStart,
    collectionDateEnd,
    deliveryDateStart,
    deliveryDateEnd,
    publishDate,
    availableUntil,
    date,
    truckTires,
    truckAxis,
    truckHeight,
    truckWidth,
    truckLength,
    mileage,
    loadingPosition,
    unloadingPosition,
    personOriginId,
    personOrigin {
      ...PersonSimple
    },
    addressOriginId,
    addressOrigin {
      ...Address
    },
    personDestinationId,
    personDestination {
      ...PersonSimple
    },
    addressDestinationId,
    addressDestination {
      ...Address
    },
    companyId,
    company {
      ...CompanySimple
    },
    serviceTakerId,
    serviceTaker {
      ...PersonSimple
    },
    serviceTakerAddressId,
    serviceTakerAddress {
      ...Address
    },
    dispatcherId,
    dispatcher {
      ...PersonSimple
    },
    receiverId,
    receiver {
      ...PersonSimple
    },
    hiredId,
    hired {
      ...PersonSimple
    },
    driverId,
    driver {
      ...FreighterDriver
    },
    vehicleId,    
    vehicle {
      ...VehicleSimple
    },
    vehicleOwnerId,    
    vehicleOwner {
      ...PersonSimple
    },        
    operationId,
    operation {
      ...FreighterOperation
    }
    products {
      ...FreightProduct
    },
    bodyworks {
      ...VehicleBodywork
    },
    vehicleTypes {
      ...VehicleType
    }
    insurances {
      ...FreightInsurance
    },
    canDisplaceToLoadingPlace,
    suggestedForDriver,
  }
  ${driverFragment}  
  ${companySimpleFragment}  
  ${personSimpleFragment}
  ${addressFragment}
  ${freightProductFragment}
  ${vehicleBodyworkFragment}
  ${vehicleTypeFragment}  
  ${freightInsuranceFragment}
  ${freighterOperationFragment}
  ${vehicleSimpleFragment}
`;