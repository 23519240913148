import { KM, KG, TON } from 'grifo-components';
export const VIEWING = 'viewing';
export const SCHEDULING = 'scheduling';
export const QUOTING = 'quoting';
export const CLOSURE = 'closure';
export const DISPLACEMENT_TO_LOADING_PLACE = 'displacement_to_loading_place';
export const WAITING_FOR_LOADING = 'waiting_for_loading';
export const LOADING = 'loading';
export const CHARGED_UP = 'charged_up';
export const TRAVELING = 'traveling';
export const WAITING_FOR_UNLOADING = 'waiting_for_unloading';
export const UNLOADING = 'unloading';
export const FINISHED = 'finished';
export const CANCELLED = 'cancelled';
export const QUANTITY_UNLOADED = 'quantity_unloaded';
export const QUANTITY_LOADED = 'quantity_loaded';
export const WITHOUT_TRACKER = 'without_tracker';
export const CELULAR = 'celular';
export const SATELLITE = 'satellite';
export const HYBRID = 'hybrid';
export const MONITORED = 'monitored';
export const WITHOUT_INSURANCE = 'without_insurance';
export const INSURED = 'insured';
export const NORMAL = 'normal';
export const URGENT = 'urgent';
export const LOADING_FREE = 'loading_free';
export const LOADING_DRIVER = 'loading_driver';
export const LOADING_COMPANY = 'loading_company';
export const LOADING_SERVICE_TAKER = 'loading_service_taker';
export const UNLOADING_FREE = 'unloading_free';
export const UNLOADING_DRIVER = 'unloading_driver';
export const UNLOADING_COMPANY = 'unloading_company';
export const UNLOADING_SERVICE_TAKER = 'unloading_service_taker';
export const OWNER = 'owner';
export const DRIVER = 'driver';
export const COMPANY = 'company';
export const SERVICE_TAKER = 'service_taker';
export const HIRED = 'hired';
export const WITHOUT_TOLL = 'without_toll';
export const TOLLED = 'tolled';
export const WITH_TOLL_VOUCHER = 'with_voucher';
export const WITHOUT_TOLL_VOUCHER = 'without_voucher';
export const COMPLETE = 'complete';
export const COMPLEMENT = 'complement';
export const WITHOUT_CANVAS = 'without_canvas';
export const ONE = 'one';
export const TWO = 'two';
export const THREE = 'three';
export const MORE = 'more';
export const SHIPPING_COMPANY = 'shipping_company';
export const SHIPPING_SERVICE_TAKER = 'shipping_service_taker';
export const BY_TRAVEL = 'by_travel';
export const WITHOUT_PRECIFICATION = 'without_precification';
export const CARGO_VALUE_PERCENTAGE = 'cargo_value_percentage';
export const TRAVEL_VALUE_PERCENTAGE = 'travel_value_percentage';
export const CIF = 'CIF';
export const FOB = 'FOB';
export const FREIGHT_METHOD_OPTIONS = [{
  value: CIF,
  label: 'CIF - Cost, Insurance and Freight'
}, {
  value: FOB,
  label: 'FOB - Free on board'
}];
export const PRICING_METHOD_OPTIONS = [{
  value: TON,
  label: 'Por tonelada'
}, {
  value: KM,
  label: 'Por KM'
}, {
  value: BY_TRAVEL,
  label: 'Por viagem'
}, {
  value: WITHOUT_PRECIFICATION,
  label: 'Sem preço'
}, {
  value: CARGO_VALUE_PERCENTAGE,
  label: '% do valor da carga'
}];
export const ADVANCE_METHOD_OPTIONS = [{
  value: TON,
  label: 'Por tonelada'
}, {
  value: KM,
  label: 'Por KM'
}, {
  value: BY_TRAVEL,
  label: 'Por viagem'
}, {
  value: WITHOUT_PRECIFICATION,
  label: 'Sem preço'
}, {
  value: CARGO_VALUE_PERCENTAGE,
  label: '% do valor da carga'
}, {
  value: TRAVEL_VALUE_PERCENTAGE,
  label: '% do frete'
}];
export const PRECIFICATION_OPTIONS = [{
  value: TON,
  label: 'Por tonelada'
}, {
  value: KM,
  label: 'Por KM'
}, {
  value: BY_TRAVEL,
  label: 'Por viagem'
}, {
  value: WITHOUT_PRECIFICATION,
  label: 'A combinar'
}, {
  value: CARGO_VALUE_PERCENTAGE,
  label: '% do valor da carga'
}];
export const MEASURE_TYPE_OPTIONS = [{
  value: KG,
  label: 'Quilograma'
}, {
  value: TON,
  label: 'Tonelada'
}];
export const INSURANCE_LIABILITY_OPTIONS = [{
  value: SHIPPING_COMPANY,
  label: 'Transportadora'
}, {
  value: SHIPPING_SERVICE_TAKER,
  label: 'Tomador do Serviço'
}];
export const TRACKER_OPTIONS = [{
  value: WITHOUT_TRACKER,
  label: 'Não'
}, {
  value: CELULAR,
  label: 'Celular'
}, {
  value: SATELLITE,
  label: 'Satélite'
}, {
  value: HYBRID,
  label: 'Híbrido'
}, {
  value: MONITORED,
  label: 'Monitorado'
}];
export const INSURANCE_OPTIONS = [{
  value: WITHOUT_INSURANCE,
  text: 'Não',
  description: 'A carga não é segurada'
}, {
  value: INSURED,
  text: 'Sim',
  description: 'A carga possui seguro'
}];
export const PRIORITY_OPTIONS = [{
  value: NORMAL,
  text: 'Normal'
}, {
  value: URGENT,
  text: 'Urgente'
}];
export const LOADING_TAX_OPTIONS = [{
  value: LOADING_FREE,
  label: 'Sem taxa'
}, {
  value: LOADING_DRIVER,
  label: 'Pago pelo motorista'
}, {
  value: LOADING_COMPANY,
  label: 'Pago pela empresa'
}, {
  value: LOADING_SERVICE_TAKER,
  label: 'Pago pelo tomador'
}];
export const UNLOADING_TAX_OPTIONS = [{
  value: UNLOADING_FREE,
  label: 'Sem taxa'
}, {
  value: UNLOADING_DRIVER,
  label: 'Pago pelo motorista'
}, {
  value: UNLOADING_COMPANY,
  label: 'Pago pela empresa'
}, {
  value: UNLOADING_SERVICE_TAKER,
  label: 'Pago pelo tomador'
}];
export const TOLL_OPTIONS = [{
  value: WITHOUT_TOLL,
  text: 'Não',
  description: 'A rota não possui pedágio'
}, {
  value: TOLLED,
  text: 'Sim',
  description: 'A rota possui pedágio'
}];
export const TOLL_METHOD_OPTIONS = [{
  value: WITH_TOLL_VOUCHER,
  label: 'Com vale-pedágio'
}, {
  value: WITHOUT_TOLL_VOUCHER,
  label: 'Sem vale-pedágio'
}];
export const TOLL_PAYING_RESPONSIBLE_OPTIONS = [{
  value: SERVICE_TAKER,
  label: 'Tomador'
}, {
  value: HIRED,
  label: 'Contratado'
}, {
  value: OWNER,
  label: 'Proprietário do veículo'
}, {
  value: DRIVER,
  label: 'Motorista'
}, {
  value: COMPANY,
  label: 'Empresa'
}];
export const KIND_OPTIONS = [{
  value: COMPLETE,
  text: 'Completa',
  description: 'Essa carga se refere a um único e isolado frete.'
}, {
  value: COMPLEMENT,
  text: 'Complementar',
  description: 'Essa carga complementa um frete com outras cargas.'
}];
export const CANVAS_OPTIONS = [{
  value: WITHOUT_CANVAS,
  label: 'Não'
}, {
  value: ONE,
  label: 'Uma lona'
}, {
  value: TWO,
  label: 'Duas lonas'
}, {
  value: THREE,
  label: 'Três lonas'
}, {
  value: MORE,
  label: 'Mais de três lonas'
}];
export const SITUATION_OPTIONS = [{
  label: 'Agendado',
  value: SCHEDULING
}, {
  label: 'Cotando',
  value: QUOTING
}, {
  label: 'Negócio fechado',
  value: CLOSURE
}, {
  label: 'Deslocando para local de carga',
  value: DISPLACEMENT_TO_LOADING_PLACE
}, {
  label: 'Aguardando carregamento',
  value: WAITING_FOR_LOADING
}, {
  label: 'Carregando',
  value: LOADING
}, {
  label: 'Carregado',
  value: CHARGED_UP
}, {
  label: 'Viajando',
  value: TRAVELING
}, {
  label: 'Aguardando descarregamento',
  value: WAITING_FOR_UNLOADING
}, {
  label: 'Descarregando',
  value: UNLOADING
}, {
  label: 'Finalizado',
  value: FINISHED
}, {
  label: 'Cancelado',
  value: CANCELLED
}];
export const NEXT_SITUATION_OPTIONS = [{
  label: 'Fechar negócio',
  value: QUOTING,
  nextSituation: CLOSURE
}, {
  label: 'Deslocar para o local de carga',
  value: CLOSURE,
  nextSituation: DISPLACEMENT_TO_LOADING_PLACE
}, {
  label: 'Aguardar carregamento',
  value: DISPLACEMENT_TO_LOADING_PLACE,
  nextSituation: WAITING_FOR_LOADING
}, {
  label: 'Carregar',
  value: WAITING_FOR_LOADING,
  nextSituation: LOADING
}, {
  label: 'Carregado',
  value: LOADING,
  nextSituation: CHARGED_UP
}, {
  label: 'Início da viagem',
  value: CHARGED_UP,
  nextSituation: TRAVELING
}, {
  label: 'Aguardando descarregamento',
  value: TRAVELING,
  nextSituation: WAITING_FOR_UNLOADING
}, {
  label: 'Descarregar',
  value: WAITING_FOR_UNLOADING,
  nextSituation: UNLOADING
}, {
  label: 'Finalizar',
  value: UNLOADING,
  nextSituation: FINISHED
}];