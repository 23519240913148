import moment from 'moment';
import { fiscalPersonParamBuilder, fiscalPersonDefaultBuilder } from 'fiscal-front';
import { sanitizeBackend } from 'grifo-components';
import { NON_INDUSTRY, INDUSTRY_OTHERS, ICMS_TO_TAX } from '../constants/PersonAccountingOptions';
export const accountingCompanyParamBuilder = (record, values) => {
  let company = {
    attributes: {
      id: record?.id || null,
      companyAccountants: values?.companyAccountants || [],
      stateIcmsApurationCode: values.stateIcmsApurationCode,
      stateIcmsExpirationDay: values.stateIcmsExpirationDay,
      accountingEntryDeadlineDay: values.accountingEntryDeadlineDay,
      person: Object.assign({}, fiscalPersonParamBuilder(record?.person || null, values).attributes, {
        scpIndicator: values.scpIndicator,
        csllBasis: values.csllBasis,
        apurationPeriod: values.apurationPeriod,
        stockAvaliationMethod: values.stockAvaliationMethod,
        presidentQualification: values.presidentQualification,
        spedFrameworkProfile: values.spedFrameworkProfile,
        icmsTaxableObligationCode: values.icmsTaxableObligationCode,
        accountingActivity: values.accountingActivity,
        regimeKind: values.regimeKind,
        creditApropriationKind: values.creditApropriationKind,
        contributionKind: values.contributionKind,
        cumulativeRegime: values.cumulativeRegime,
        specialSituation: values.specialSituation,
        specialSituationDate: values.specialSituationDate,
        industryActivity: values.industryActivity,
        industryClassification: values.industryClassification,
        bookNumber: values?.bookNumber?.toString() || null,
        nireCode: values?.nireCode?.toString() || null
      })
    }
  };
  return company;
};
export const accountingCompanyDefaultBuilder = record => {
  let object = record || {};
  return Object.assign({}, fiscalPersonDefaultBuilder(record?.person), {
    companyAccountants: (object.companyAccountants || []).map(companyAccountant => {
      return {
        id: companyAccountant.id,
        profile: companyAccountant.profile,
        personId: companyAccountant.personId,
        start: moment(companyAccountant.start),
        companyId: companyAccountant.companyId,
        active: companyAccountant.active,
        finish: companyAccountant.finish ? moment(companyAccountant.finish) : null,
        crcCode: companyAccountant.crcCode,
        crcSequential: companyAccountant.crcSequential,
        expirationDate: moment(companyAccountant.expirationDate),
        officeId: companyAccountant.officeId,
        stateId: companyAccountant.stateId
      };
    }),
    scpIndicator: object.person?.scpIndicator,
    csllBasis: object.person?.csllBasis,
    apurationPeriod: object.person?.apurationPeriod,
    stockAvaliationMethod: object.person?.stockAvaliationMethod,
    presidentQualification: object.person?.presidentQualification,
    spedFrameworkProfile: object.person?.spedFrameworkProfile,
    icmsTaxableObligationCode: object.person?.icmsTaxableObligationCode,
    accountingActivity: object.person?.accountingActivity,
    regimeKind: object.person?.regimeKind,
    creditApropriationKind: object.person?.creditApropriationKind,
    contributionKind: object.person?.contributionKind,
    cumulativeRegime: object.person?.cumulativeRegime,
    specialSituation: object.person?.specialSituation,
    specialSituationDate: sanitizeBackend(object.person?.specialSituationDate),
    bookNumber: object.person?.bookNumber,
    nireCode: object.person?.nireCode,
    stateIcmsApurationCode: object.stateIcmsApurationCode,
    stateIcmsExpirationDay: object.stateIcmsExpirationDay,
    accountingEntryDeadlineDay: object.accountingEntryDeadlineDay,
    industryActivity: object.person?.industryActivity || NON_INDUSTRY,
    industryClassification: object.person?.industryClassification || INDUSTRY_OTHERS
  });
};