import gql from 'graphql-tag';
import { contractFragment } from '../fragments/Contract';
export const GQL_CONTRACTS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $contractFilter: ContractFilter
) {    
    contractsPaginated(first: $pageSize, after: $cursor, contractFilter: $contractFilter
) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Contract
        }
      }
    }    
  }
  ${contractFragment}
`;
export const GQL_CONTRACT_STORE = gql`
  mutation StoreContract($attributes: ContractAttributes!) {
    storeContract(input: { attributes: $attributes }) {
      contract {
        ...Contract
      }
      errors
    }
  }
  ${contractFragment}
`;