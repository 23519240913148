import { ASSET_ACCOUNT } from "../constants/AccountOptions";
import { dateOrToday, sanitizeBackend } from 'grifo-components';
export const referentialAccountParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      start: values.start,
      finish: values.finish,
      kind: values.kind
    }
  };
};
export const referentialAccountDefaultBuilder = record => {
  let object = record || {};
  return {
    name: object.name,
    start: dateOrToday(object.start),
    finish: sanitizeBackend(object.finish),
    kind: object.kind || ASSET_ACCOUNT
  };
};