import { dateOrToday, sanitizeBackend } from 'grifo-components';
import { PER_QUANTITY } from '../constants/contracts/ContractFieldsOptions';
import { defaultBuilder, paramBuilder } from "./FreightCommonBuilder";
export const contractParamBuilder = (record, values) => {
  const contractValues = {
    number: values.number,
    totalDistanceTravelled: values.totalDistanceTravelled,
    totalFreights: values.totalFreights,
    periodOfValidity: values.periodOfValidity,
    start: values.start ? values.start : undefined,
    finish: values.finish ? values.finish : undefined
  };
  const commonValues = paramBuilder(record, values, true);
  return {
    attributes: {
      ...contractValues,
      ...commonValues
    }
  };
};
export const contractDefaultBuilder = (record, props = {}) => {
  const object = record || {};
  const contractValues = {
    number: object.number,
    totalDistanceTravelled: object.totalDistanceTravelled || 0,
    totalFreights: object.totalFreights || 0,
    periodOfValidity: object.periodOfValidity || PER_QUANTITY,
    start: dateOrToday(object.start),
    finish: sanitizeBackend(object.finish)
  };
  const commonValues = defaultBuilder(object);
  return {
    ...contractValues,
    ...commonValues
  };
};