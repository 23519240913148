import { ACCEPTED_BY_PERSON, DISMISSED_BY_PERSON } from "../constants/availableFreights/AvailableFreightFieldsOptions";
export const freightBidParamBuilder = (record, values) => {
  let object = record.lastBid || record.offer;
  return {
    attributes: {
      freightId: record.id,
      personId: values.personId,
      vehicleId: values.vehicleId,
      observation: values.observation,
      situation: values.situation,
      advanceMethod: values.advanceMethod,
      advanceUnitaryValue: values.advanceUnitaryValue,
      advanceValue: values.advanceValue,
      pricingMethod: object.pricingMethod,
      pricingValue: object.pricingValue,
      value: object.value,
      mileage: object.mileage,
      cargoGrossWeight: object.cargoGrossWeight,
      cargoValue: object.cargoValue
    }
  };
};
export const freightBidDefaultBuilder = (record, props = {}) => {
  let object = ([ACCEPTED_BY_PERSON, DISMISSED_BY_PERSON].includes(props.action) ? record.companyBid : record.lastBid) || record.offer;
  return {
    freightId: record.id,
    personId: props.personId,
    vehicleId: props.vehicleId,
    situation: props.action,
    observation: object.observation,
    pricingMethod: object.pricingMethod,
    pricingValue: object.pricingValue,
    value: object.value,
    advanceMethod: object.advanceMethod,
    advanceValue: object.advanceValue,
    advanceUnitaryValue: object.advanceUnitaryValue,
    mileage: object.mileage,
    cargoGrossWeight: object.cargoGrossWeight,
    cargoValue: object.cargoValue
  };
};
export const negotiationHistoryDefaultBuilder = record => {
  return {
    ...record
  };
};