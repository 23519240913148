import gql from 'graphql-tag';
import { personFragment, stateFragment } from 'admin-front';
export const companyAccountantFragment = gql`
  fragment CompanyAccountant on CompanyAccountant {
    id,
    profile,
    active,
    start,
    finish,
    personId,
    crcCode,
    crcSequential,
    expirationDate,
    officeId,
    stateId,
    state {
      ...State
    },
    person {
      ...Person
    },
  }
  ${stateFragment}
  ${personFragment}
`;