import gql from "graphql-tag";
export const financialStatsFragment = gql`
  fragment FinancialStats on FinancialStats {
    id,
    toPayTotal,
    toPayDue,
    toPayToday,
    toPayInTheWeek,
    toPayInTheMonth,
    toPayInTheFuture,
    paidToday,
    paidTodayRate,
    toReceiveTotal,
    toReceiveDue,
    toReceiveToday,
    toReceiveInTheWeek,
    toReceiveInTheMonth,
    toReceiveInTheFuture,
    receivedToday,
    receivedTodayRate,
  }
`;