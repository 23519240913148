import gql from "graphql-tag";
import { operationParentFragment } from "../fragments/FreighterOperationParent";
export const GQL_OPERATIONS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $freightOperationFilter: FreightOperationFilter) {    
    freighterOperationsPaginated(first: $pageSize, after: $cursor, freightOperationFilter: $freightOperationFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...FreighterOperationParent
        }
      }
    }    
  }
  ${operationParentFragment}
`;
export const GQL_ALL_AVAILABLE_OPERATION = gql`
  query GetAll($all: Boolean) {    
    freighterOperations(all: $all) {
      ...FreighterOperationParent
    }
  }
  ${operationParentFragment}
`;
export const GQL_FREIGHTER_OPERATION_STORE = gql`
  mutation StoreFreighterOperation($attributes: FreighterOperationAttributes!) {
    storeFreighterOperation(input: {attributes: $attributes}) { 
      freighterOperation {
        ...FreighterOperationParent
    }, 
      errors 
    } 
  }
  ${operationParentFragment}   
`;
export const GQL_OPERATION_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: { id: $id, className: "Freighter::Operation" }) {
      id,
      discardedAt
    }
  }
`;
export const GQL_SEARCH_OPERATION_BY_ID = gql`
  query GetById($id: String) {    
    freighterOperations(id: $id) { 
      ...FreighterOperationParent
    }
  }
  ${operationParentFragment}   
`;